<template>
  <b-tab title="Produtos (Opcional)" @click="() => store.currentTab = 3" :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab && !store.isUpdate">
    <b-row align-v="center">
      <b-col md="10">
        <b-form-group label="Nome" label-for="name">
          <VueSelect ref="buscarProduto" :filterable="false" @input="setProduto" :options="produtosFetched"
            :class="{ hasErrors: produtoError, inputFilled: produtoFilled }" @search="onSearchProduto"
            v-model="descricao">
            <template slot="no-options"> Informe o produto pelo nome </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>
                  {{ option.codigo }} - {{ option.descricao }}
                  <span id="kitInfo" v-if="option.isKit" :title="getKitInfo(option)" v-b-tooltip.hover.html.right>
                    <InformationOutline :size="14" />
                  </span>
                </div>
                <span>{{ !option.isKit ? `R$: ${numberToReal(option.preco)} - ` + `QTD: ${option.quantidade}` : `R$
                  ${numberToReal(option.totalPrice)}` }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.label }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col class="mt-1">
        <b-dropdown id="dropdown-dropright" dropright variant="primary" v-b-tooltip.hover.right
          title="Mudar tipo de filtro">
          <template #button-content>
            <FilterOutline :size="20" class="mt-" />
          </template>
          <b-dropdown-item @click="() => {
            typeFilter = 1;
          }
            " :class="typeFilter === 1 ? 'bg-primary' : ''">
            <p :class="typeFilter === 1 ? 'text-white' : ''">Começando com</p>
          </b-dropdown-item>
          <b-dropdown-item @click="() => {
            typeFilter = 2;
          }
            " :class="typeFilter === 2 ? 'bg-primary' : ''">
            <p :class="typeFilter === 2 ? 'text-white' : ''">Terminando com</p>
          </b-dropdown-item>
          <b-dropdown-item @click="() => {
            typeFilter = 3;
          }
            " :class="typeFilter === 3 ? 'bg-primary' : ''">
            <p :class="typeFilter === 3 ? 'text-white' : ''">Contendo</p>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mt-10">
      <b-col>
        <b-form-group label="Quantidade" label-for="quantidade">
          <money class="form-control" id="quantidade" name="quantidade" v-model="quantidade" v-bind="quantityOptions">
          </money>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Unitário" label-for="preco">
          <money class="form-control" id="preco" name="preco" v-model="preco" v-bind="priceOptions">
          </money>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Desconto" label-for="desconto">
          <money class="form-control" id="desconto" name="desconto" v-model="desconto" @input="setDesconto"
            v-bind="configMask">
          </money>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Total R$" label-for="total">
          <money disabled class="form-control" id="total" name="total" v-model="total" v-bind="priceOptions">
          </money>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <button @click="addProduto(produtoSelected)">
          <Plus class="btn btn-sm btn-info btn-hover-info btn-icon mr-2" />
        </button>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <TableApp item="produto" subTitulo="" :data="produtos" :fields="fields" classFilterColumn="nome" ref="nome">
          <template slot="customRow" scope="props">
            <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'descricao'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ props.rowData.product.descricao }}
                  <span v-b-tooltip.hover.right="'Item atendeu a regra de preço de atacado'"
                    v-if="props.rowData.product.tipo_atacado">
                    <InformationOutline :size="14" />
                  </span>
                </div>
              </div>
            </span>
            <span slot="quantidade" class="pl-0 pt-8" v-if="props.field == 'quantidade'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ numberToReal(props.rowData.quantity) }}
                </div>
              </div>
            </span>
            <span slot="preco" class="pl-0 pt-8" v-if="props.field == 'preco'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ numberToReal(props.rowData.price) }}
                </div>
              </div>
            </span>
            <span slot="desconto" v-if="props.field == 'desconto'"
              class="text-dark-75 font-weight-bolder d-block font-size-lg">
              R$
              {{ numberToReal(props.rowData.discount, 2) }}
            </span>
            <span slot="total" v-if="props.field == 'total'" class="text-dark-75 font-weight-bolder d-block font-size-lg">
              R$
              {{
                numberToReal(
                  props.rowData.quantity * props.rowData.price -
                  props.rowData.discount, 2
                )
              }}
            </span>
            <span slot="actions" v-if="props.field == 'actions'">
              <div class="row-actions" style="text-align: right !important">
                <span style="overflow: visible; position: relative; width: 130px">
                  <div class="action-buttons">
                    <button @click="
                      openModalProduto(
                        props.rowData,
                        props.rowData.currentIndex
                      )
                      " class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
                      <Pencil :size="16" title="Editar" />
                    </button>
                    <button @click="
                      handleDeleteProduto(
                        props.rowData,
                        props.rowData.currentIndex
                      )
                      " class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2">
                      <TrashCanIcon :size="16" />
                    </button>
                  </div>
                </span>
              </div>
            </span>
          </template>
        </TableApp>
        <b-row v-if="produtos.length > 0">
          <b-col></b-col>
          <b-col></b-col>
          <b-col><span class="text-primary font-weight-bolder">Total Itens:</span> {{ produtos.reduce((acc, produto) =>
            acc + produto.quantity, 0) }}</b-col>
          <b-col><span class="text-primary font-weight-bolder">Total R$:</span>{{ numberToReal(produtos.reduce((acc,
            produto) => acc + produto.totalPrice, 0), 'preco') }}</b-col>
        </b-row>
        <ModalProduto :close="closeModalProduto" :changeProduto="saveEditProduto" :produto="produtoToEdit"
          :open="modalProdutoIsOpen" v-if="modalProdutoIsOpen" />
      </b-col>
    </b-row>
    <VariacaoProdutoModal :addProduto="addProduto" :produto="produtoSelected" @onAddProduto="({ newPreco, newQuantidade, newTotal }) => {
      preco = formatNumberToFloat(newPreco);
      quantidade = newQuantidade;
      total = newTotal;
    }" />
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import { Money } from "v-money";
import { MONEY as settingMoney, priceOptions, quantityOptions } from "@/modules/Application";
import "vue-select/dist/vue-select.css";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import InformationOutline from "vue-material-design-icons/InformationOutline.vue";
import Plus from "vue-material-design-icons/PlusThick.vue";
import TableApp from "@/components/Table/Table3.vue";
import ModalProduto from "./ModalProduto.vue";
import { validationMixin } from "vuelidate";
import ProdutoData, { ProdutoDataRequired } from "../ProdutoData";
import { SistemaMixin, ValidacaoMixin, FormatMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeOS";
import FilterOutline from "vue-material-design-icons/Filter";
import VariacaoProdutoModal from "@/components/VariacaoProdutoModal"

export default {
  name: "app-os-form-ProdutoTab",
  components: {
    VueSelect,
    ModalProduto,
    TableApp,
    Pencil,
    Plus,
    TrashCanIcon,
    Money,
    FilterOutline,
    InformationOutline,
    VariacaoProdutoModal,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      configMask: settingMoney,
      quantityOptions,
      priceOptions,
      typeFilter: 3,
      fields: [
        {
          label: "Nome",
          field: "descricao",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Qtd",
          field: "quantidade",
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Unitário",
          field: "preco",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Desconto",
          field: "desconto",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "Total",
          field: "total",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "20%",
        },
      ],
      form: {},
      descricao: "",
      quantidade: 1,
      preco: 0,
      desconto: 0,
      total: 0,
      produtoSelected: null,
      estoqueProduto: 0,
      produtosFetched: [],
      produtos: [],
      produtoToEdit: null,
      modalProdutoIsOpen: false,
      dataSendToApi: {},
      produtoError: false,
      produtoFilled: false,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin, FormatMixin],
  validations: {
    form: ProdutoDataRequired,
  },
  watch: {
    form(newValue) {
      this.store.formProduto = newValue;
    },
    preco(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.total = newValue * this.quantidade;
      }
    },
    quantidade(newValue, oldValue) {
      if (oldValue !== newValue) {
        const isAtacado = newValue >= this.produtoSelected.quantidade_atacado &&
          this.produtoSelected.preco_atacado > 0 &&
          this.store.formGerais.cliente.tipo_atacado

        this.preco = isAtacado ?
          this.formatNumberToFloat(this.produtoSelected.preco_atacado)
          :
          this.produtoSelected.preco;

        this.total = newValue * this.formatNumberToFloat(this.preco);
        this.produtoSelected.total = this.total
        this.produtoSelected.tipo_atacado = isAtacado
        this.produtoSelected.quantidade = newValue;
      }
    },
    desconto(newValue, oldValue) {
      if (oldValue !== newValue && !isNaN(newValue) && newValue !== "") {
        this.total = this.quantidade * this.preco - parseFloat(newValue);
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),

    async addProduto(produto, isKit) {
      if (_.isEmpty(produto)) return;

      const produtoFormatted = !isKit ? {
        ...produto,
        preco: this.preco,
        quantidade: this.quantidade,
        desconto: this.desconto,
        total: this.total,
      } : { ...produto };
      this.produtos = [
        ...this.produtos, !isKit ? {
          product: produtoFormatted,
          price: this.preco,
          quantity: this.quantidade,
          totalPrice: this.total,
          discount: this.desconto,
          active: true,
          currentIndex: this.produtos.length,
          ...(produto.variavel && { combinacao: produto.combinacao })
        } : { ...produto, product: { ...produto }, ...(produto.variavel && { combinacao: produto.combinacao }) }];
      const subtotal = this.produtos.reduce(
        (acc, produto) =>
          acc + (produto.quantity * produto.price - produto.discount),
        0
      );
      this.form = {
        produtos: [...this.produtos],
        subtotal,
      };

      this.clearFields();
      this.store.formFaturamento.pagamentos = [];
      if (this.store.formFaturamento.forma_pagamento.descricao === "Nenhum") {
        this.store.formFaturamento.pagamentos[0].valor =
          this.store.formImpostos.totalNFE;
      }

      this.produtoError = false;
    },
    clearFields() {
      this.descricao = "";
      this.estoqueProduto = 0;
      this.quantidade = 1;
      this.preco = 0;
      this.total = 0;
      this.desconto = 0;
      this.produtoSelected = null;
      this.produtoFilled = false;
    },
    openModalProduto({ product }, currentIndex) {
      this.modalProdutoIsOpen = true;
      this.produtoToEdit = {
        ...product,
        currentIndex,
      };
    },
    handleDeleteProduto(item) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir o produto " + item.product.descricao + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const aux = this.produtos
              .filter((produto) => produto.currentIndex !== item.currentIndex)
              .map((item, index) => ({
                ...item,
                currentIndex: index,
              }));
            this.form.produtos = [...aux];
            this.produtos = [...aux];
            const subtotal = this.produtos.reduce(
              (acc, produto) =>
                acc + (produto.quantity * produto.price - produto.discount),
              0
            );
            this.form = {
              produtos: [...this.produtos],
              subtotal,
            };
            this.store.formFaturamento.pagamentos = [];
            if (
              this.store.formFaturamento.forma_pagamento.descricao === "Nenhum"
            ) {
              this.store.formFaturamento.pagamentos[0].valor =
                this.store.formImpostos.totalNFE;
            }
          }
        });
    },
    closeModalProduto() {
      this.modalProdutoIsOpen = false;
    },
    setDesconto() {
      if (!this.produtoSelected) return;
      this.form.total =
        this.form.preco * this.form.quantidade - this.form.desconto;
      this.produtoSelected.total = (parseFloat(this.produtoSelected.preco) * parseFloat(this.produtoSelected.quantidade)) - this.produtoSelected.desconto;
    },
    validateProdutoState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async saveEditProduto(newEditedProduto) {
      this.form.produtos[newEditedProduto.currentIndex] = {
        ...this.form.produtos[newEditedProduto.currentIndex],
        product: newEditedProduto,
        price: newEditedProduto.preco,
        quantity: newEditedProduto.quantidade,
        totalPrice:
          newEditedProduto.preco * newEditedProduto.quantidade -
          parseFloat(newEditedProduto.desconto),
        discount: parseFloat(newEditedProduto.desconto),
      };
      this.form.subtotal = this.form.produtos.reduce(
        (acc, produto) =>
          acc + produto.price * produto.quantity - produto.discount,
        0
      );
      this.produtos = _.cloneDeep(this.form.produtos);
      this.store.formProduto = _.cloneDeep(this.form);
      this.modalProdutoIsOpen = false;
    },

    onSearchProduto(search, loading) {
      if (search.length > 1 && search[0] === '+') {
        loading(true);
        this.searchKit(loading, search, this);
        return;
      }
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchProdutos(loading, search, this);
      }
    },
    searchProdutos: _.debounce(async (loading, search, vm) => {
      vm.produtosFetched = await vm.fetchProdutos(search, vm.typeFilter);
      loading(false);
    }, 350),
    searchKit: _.debounce(async (loading, search, vm) => {
      const { data } = await vm.$http.post('/kit/get-kit', { search });
      vm.produtosFetched = data.map(kit => ({
        ...kit,
        descricao: kit.nome,
        isKit: true,
      }))
      loading(false);
    }, 350),
    getKitInfo(kitData) {
      const description = kitData.productOfTradeCollection
        .reduce((acc, produto, index) => acc + `${index + 1} - ${produto.product.descricao} - QTD: ${produto.product.quantidade}<br/>`, '')
      return description;
    },
    setProduto(produto) {
      if (!produto) return;
      this.descricao = produto.descricao;
      if (produto?.variavel) {
        this.produtoSelected = produto;
        this.$bvModal.show('variacaoModal');
        return;
      };
      if (produto?.isKit) {
        produto.productOfTradeCollection.forEach((product, index) => {
          const produtoToAdd = {
            ...product.product,
            price: product.product.preco,
            quantity: product.product.quantidade,
            totalPrice: product.product.total,
            discount: product.product?.desconto ?? 0,
            active: true,
            currentIndex: index,
          }
          this.addProduto(produtoToAdd, true)
        })
        return;
      }
      this.estoqueProduto = produto.quantidade;
      const preco_atacado = produto?.preco_atacado ?? 0;
      const qtd_atacado = produto?.quantidade_atacado ?? 0;
      this.quantidade = 1;

      const isAtacadoValue = this.quantidade >= qtd_atacado && preco_atacado > 0 && this.store.formGerais.cliente.tipo_atacado

      if (isAtacadoValue) {
        this.preco = parseFloat(preco_atacado);
      } else this.preco = parseFloat(produto.preco)

      this.total = this.quantidade * parseFloat(this.preco);

      this.produtoSelected = {
        ...produto,
        preco: this.preco,
        quantidade: this.quantidade,
        tipo_atacado: isAtacadoValue
      };
      this.produtoSelected.total = this.total;

      this.produtoFilled = true;
    },
  },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      const { produtos } = this.$route.params.osData;
      this.produtos = produtos ? produtos.map((produto) => {
        const produtoFormatted = {
          ...produto.product,
          preco: produto.product.preco,
          quantidade: produto.product.quantidade,
          desconto: produto.product.desconto,
          total: produto.product.total,
        };
        return {
          product: produtoFormatted,
          price: produto.price,
          quantity: produto.quantity,
          totalPrice: produto.totalPrice,
          discount: produto.discount,
          active: produto.active,
          currentIndex: produto.currentIndex,
        };
      }) : [];
      const subtotal = this.produtos.reduce(
        (acc, produto) =>
          acc + (produto.quantity * produto.price - produto.discount),
        0
      );
      this.form = {
        produtos: [...this.produtos],
        subtotal,
      };
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 3;
    },
  },
};
</script>
