<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-5" v-if="titulo != null">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
              titulo
          }}</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
              subTitulo
          }}</span>
        </h3>
        <div class="card-toolbar">
          <a v-if="acaoBtn != null" :href="acaoBtn" class="btn btn-dark font-weight-bolder font-size-sm">{{ textoBtn
          }}</a>
          <a v-if="acaoBtnTwo != null" :href="acaoBtnTwo" class="btn btn-dark font-weight-bolder font-size-sm"
            style="margin-left: 5px">{{ textoBtnTwo }}</a>
        </div>
      </div>
      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <div class="table-responsive">
            <vue-good-table 
              mode="remote" 
              theme="polar-bear" 
              styleClass="vgt-table table-striped"
              max-height="300px"
              @on-page-change="onPageChange" 
              @on-sort-change="onSortChange" 
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange" 
              :totalRows="totalRecords" 
              :isLoading.sync="isLoading"
              :pagination-options="{
                perPage: 5,
                mode: 'pages',
                enabled: false,
                nextLabel: 'Próximo',
                prevLabel: 'Voltar',
                rowsPerPageLabel: '',
                ofLabel: 'de',
                allLabel: 'Todos',
                dropdownAllowAll: false,
                infoFn: (params) => '',
              }"
              :rows="data" 
              :columns="fields">
              <template slot="loadingContent">
                <Loading></Loading>
              </template>
              <template slot="emptystate">
                <div class="vgt-center-align vgt-text-disabled">
                  Nenhum {{ item }} encontrado
                </div>
              </template>
              <template slot="table-row" slot-scope="props">
                {{ props.slot }}
                <template v-if="props.column.slot">
                  <slot name="customRow" :rowData="props.row" :field="props.column.field"></slot>
                </template>
                <template v-else>
                  {{ props.formattedRow[props.column.field] }}
                </template>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/Loading";

export default {
  name: "table-app",
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      storageName: null,
      sortOptions: {
        enabled: true,
        initialSortBy: null,
      },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 5,
      },
    };
  },
  props: {
    titulo: {
      type: String,
      default: null,
    },
    subTitulo: {
      type: String,
      default: null,
    },
    acaoBtn: {
      type: String,
      default: null,
    },
    textoBtn: {
      type: String,
      default: null,
    },
    fields: {
      type: Array,
      required: true,
    },
    item: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true,
    },
    acaoBtnTwo: {
      type: String,
      default: null,
    },
    textoBtnTwo: {
      type: String,
      default: null,
    },
  },
  components: {
    VueGoodTable,
    Loading,
  },
  methods: {
    ...mapActions(["setLoading"]),
    dateRangeFilter(data, filterString) {
      let dateRange = filterString.split("to");
      let startDate = Date.parse(dateRange[0]);
      let endDate = Date.parse(dateRange[1]);
      return (data =
        Date.parse(data) >= startDate && Date.parse(data) <= endDate);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },
    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },
    onSortChange(params) {
      localStorage.setItem(`${this.storageName}sortOptions`, JSON.stringify(params[0]))
      if (!params[0]) {
        return;
      }

      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
    },
    getSortOptionOnStorage() {
      const sortStorage = localStorage.getItem(`${this.storageName}sortOptions`);
      if (sortStorage) this.sortOptions.initialSortBy = JSON.parse(sortStorage);
    },
    onColumnFilter(params) {
      this.updateParams(params);
    },
    refresh() {
    },
  },
  created() {
    this.storageName = this.titulo.replaceAll(' ', '');
    this.getSortOptionOnStorage();
  },
};
</script>

<style>
::-webkit-scrollbar-track {
  background: #ffece5 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ee7361 !important;
}

.vgt-table.bordered th {
  border: 0 !important;
  background-color: #f3f6f9 !important;
  border-bottom: 0 !important;
  letter-spacing: 1px !important;
  font-weight: 600;
  color: #b5b5c3 !important;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.vgt-wrap__footer {
  background-color: #f3f6f9 !important;
  color: #b5b5c3 !important;
  border: 0 !important;
}

.vgt-table th.sortable button:before {
  border-top: 5px solid #7e8299;
}

.vgt-table th.sortable button:after {
  border-bottom: 5px solid #7e8299;
}

table.vgt-table td {
  padding: 0.75rem !important;
  border-bottom: 1px solid #fff !important;
  color: #3f4254 !important;
  vertical-align: middle !important;
  font-weight: 600 !important;
}

table.vgt-table {
  border: 0 !important;
}

.vgt-table.polar-bear thead th {
  color: #b5b5c3;
  font-weight: 600;
  border-bottom: 1px solid #e3e8ee;
  background: transparent !important;
}

.vgt-table.polar-bear thead th .vgt-left-align {
  background: transparent !important;
}

.vgt-wrap.polar-bear .vgt-wrap__footer {
  background: transparent !important;
  margin-top: 15px;
}

.vgt-wrap.polar-bear .vgt-wrap__footer .footer__row-count__label {
  display: none;
}

.vgt-table.polar-bear thead th {
  border: 0;
}

.vgt-wrap {
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%) !important;
  border-radius: 0.42rem !important;
}

.vgt-responsive {
  border-radius: 0.42rem !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f3f6f9;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

</style>
