export default {
  data() {
    return {
      pessoa: []
    };
  },
  methods: {
    async getPessoa(id) {
      console.log(id);
      const { data } = await this.$http.post("/pessoa/find", {
        id_pessoa: id
      });
      this.pessoa = data;
      return data;
    },
    async getPessoaCodigo(codigo) {
      const { data } = await this.$http.post("/pessoa/codigo", {
        codigo
      });
      return data;
    },
    async getPessoaNome(nome) {
      const { data } = await this.$http.post("/pessoa/nome", {
        nome,
      });
      return data;
    },
    async getFornecedorNome(nome) {
      const { data } = await this.$http.post("/pessoa/get-fornecedor-by-name", {
        nome,
      });
      return data;
    },
  },
};
