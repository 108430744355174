import clientesLista from "@/modules/Clientes/pages/Index";
import novoCliente from "@/modules/Clientes/pages/Form";
import cobrancaCliente from "@/modules/Clientes/pages/Cobranca";
import contratoEditar from "@/modules/Clientes/pages/Contrato";

export default [
  {
    name: "clientesLista",
    path: "/clientes",
    component: clientesLista,
    meta: { title: "Clientes - Nuts" },
  },
  {
    name: "clientesNovo",
    path: "/clientesNovo",
    component: novoCliente,
    meta: { title: "Clientes - Nuts" },
  },
  {
    name: "clientesCobranca",
    path: "/clientesCobranca",
    component: cobrancaCliente,
    meta: { title: "Clientes - Nuts" },
  },
  {
    name: "clientesContratoEditar",
    path: "/clientes/:id_empresa/contrato",
    component: contratoEditar,
    meta: { title: "Clientes - Nuts" },
  },
];
