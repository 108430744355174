<template>
  <div class="card-form" style="margin-top: 20px">
    <div v-if="!isPayment">
      <div style="text-align: center">
        <img
          alt="Logo"
          :src="layoutConfig('self.logo.icone')"
          class="max-h-80px"
        />
      </div>
      <div class="wizard-label" style="margin-top: 20px">
        <h4 class="wizard-title" style="text-align: center; color: #ee7361">
          Veja abaixo como realizar o pagamento do Pix.
        </h4>
        <p class="wizard-title" style="text-align: center; color: #ee7361">
          1 - Abra o app do seu banco ou instituição financeira e acesso
          ambiente do pix
        </p>
        <p class="wizard-title" style="text-align: center; color: #ee7361">
          2 - Selecione a opção pagar com Qr Code e escanei o código abaixo
        </p>
        <p class="wizard-title" style="text-align: center; color: #ee7361">
          3 - Confirme as informações e finalize o pagamento
        </p>
      </div>
      <div class="card-list-pix">
        <div class="card-form-assinatura__inner">
          <div class="card-input">
            <h5 style="text-align: center; color: #ee7361">
              Valor: R$ {{ numberToReal(valorPix, 2) }}
            </h5>
            <h5 style="text-align: center; color: #ee7361">
              Benificiário: {{ benificiario }}
            </h5>
            <h5 style="text-align: center; color: #ee7361">
              Vencimento: {{ formatDate(dataValidadePix) }}
            </h5>
            <h3 style="text-align: center">
              <transition name="shake" mode="out-in">
                <QrCodeVue :value="qrCodPix" size="300" />
              </transition>
            </h3>
            <button class="card-form__button" v-on:click="copyQrCode">
              Copiar código PIX
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isPayment">
      <div style="text-align: center">
        <img
          alt="Logo"
          :src="layoutConfig('self.logo.icone')"
          class="max-h-80px"
        />
      </div>
      <div class="card-list-pix" style="margin-top: 100px">
        <div class="card-form-assinatura__inner" style="text-align: center">
          <Check size="100" style="color: green !important" />
          <div class="card-input">
            <h5 style="text-align: center">
              Valor Pago: R$
              {{ numberToReal(paymentEvent.amount, 2) }}
            </h5>
            <h5 style="text-align: center">
              Data Pagamento:
              {{ formatDate(paymentEvent.created_at) }}
            </h5>
            <h5 style="text-align: center; margin-top: 50px">
              Muito obrigado!
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import QrCodeVue from "qrcode.vue";
import { httpResetLogin } from "../../../configurations/axios";
import { SistemaMixin } from "@/modules/Application";
import Check from "vue-material-design-icons/Check";

export default {
  name: "app-contareceber-pix",
  mixins: [SistemaMixin],
  data() {
    return {
      contareceber: {},
      loader: true,
      mobileNumber: "",
      valorPix: 0,
      qrCodPix: null,
      dataValidadePix: null,
      benificiario: null,
      paymentEvent: {},
      isPayment: false,
      id_transaction_digital: null,
    };
  },
  components: {
    QrCodeVue,
    Check,
  },
  watch: {
    paymentEvent(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.Echo.leaveChannel("boletoChannel");
      }
    },
  },
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    async verificaContasReceber() {
      this.setLoading(true);
      await this.$http
        .post("/boletos/webhook-transacations")
        .then(() => {
          window.Echo.channel("boletoChannel").listen(
            ".boletoEvent",
            (message) => {
              if (message) {
                message.message.payload.object.history.forEach((item) => {
                  if (
                    item.operation_type === "paid" &&
                    item.transaction === this.id_transaction_digital
                  ) {
                    this.paymentEvent = item;
                    this.isPayment = true;
                    this.setLoading(false);
                  }
                });
              }
            }
          );
        })
        .catch(() => {});
    },
    async getInit() {
      try {
        this.setLoading(true);
        const id_transaction_digital =
          this.$route.params.id_transaction_digital;

        if (!id_transaction_digital) {
          this.setLoading(false);
          return this.$toast.error("Não foi possível gerar o QrCode PIX!");
        }

        const contaReceber = await httpResetLogin.post("pix/busca-pix", {
          id_transaction: id_transaction_digital,
        });

        if (!contaReceber.data.qr_code_pix) {
          this.setLoading(false);
          return this.$toast.error("Não foi possível gerar o QrCode PIX!");
        }
        this.id_transaction_digital = id_transaction_digital;
        this.qrCodPix = contaReceber.data.qr_code_pix;
        this.valorPix = contaReceber.data.valor;
        if (contaReceber.data.valor_total > 0) {
          this.valorPix = contaReceber.data.valor_total;
        }
        this.dataValidadePix = contaReceber.data.pix.pix_expiration_date_time;
        this.benificiario = contaReceber.data.empresa.nome;

        this.setLoading(false);
      } catch {
        return;
      }
    },
    async copyQrCode() {
      await navigator.clipboard.writeText(this.qrCodPix);
      this.$toast.success("Código copiado com sucesso!");
    },
  },
  mounted() {
    this.getInit();
    this.verificaContasReceber();
  },
};
</script>
<style scoped>
svg {
  outline-width: 0;
  outline-color: transparent;
  color: green;
}
</style>