<template>
  <div>
    <h4>Financeiro</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Contas Receber:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial"
                    label-for="data_inicial"
                    label="Data Inicial Venc."
                  >
                    <b-form-input type="date" v-model="data_inicial">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final"
                    label-for="data_final"
                    label="Data Final Venc."
                  >
                    <b-form-input type="date" v-model="data_final">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    id="statusSelecionadoReceber"
                    label-for="statusSelecionadoReceber"
                    label="Status"
                    class="mr-5"
                  >
                    <b-form-select v-model="statusSelecionadoReceber">
                      <b-form-select-option
                        v-for="s in statusContaReceber"
                        :key="s.id + 'status'"
                        :value="s.descricao"
                      >
                        {{ s.descricao }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <div class="border-bottom border-light py-3">
                  <b-col md="12">
                    <p class="font-weight-bolder text-dark mb-2">
                      Considerar pela data de recebimento
                    </p>
                    <span class="switch switch-icon">
                      <label v-b-tooltip.hover.right="'Não/Sim'">
                        <input type="checkbox" v-model="data_recebimento" />
                        <span></span>
                      </label>
                    </span>
                  </b-col>
                </div>
              </b-row>
              <button
                @click="gerarContasReceber()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Contas a Pagar:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_pagar"
                    label-for="data_inicial_pagar"
                    label="Data Inicial Venc."
                  >
                    <b-form-input type="date" v-model="data_inicial_pagar">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_pagar"
                    label-for="data_final_pagar"
                    label="Data Final Venc."
                  >
                    <b-form-input type="date" v-model="data_final_pagar">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    id="statusSelecionadoPagar"
                    label-for="statusSelecionadoPagar"
                    label="Status"
                    class="mr-5"
                  >
                    <b-form-select v-model="statusSelecionadoPagar">
                      <b-form-select-option
                        v-for="s in statusContaPagar"
                        :key="s.id + 'status'"
                        :value="s.descricao"
                      >
                        {{ s.descricao }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <div class="border-bottom border-light py-3">
                  <b-col md="12">
                    <p class="font-weight-bolder text-dark mb-2">
                      Considerar pela data de pagamento
                    </p>
                    <span class="switch switch-icon">
                      <label v-b-tooltip.hover.right="'Não/Sim'">
                        <input type="checkbox" v-model="data_pagamento" />
                        <span></span>
                      </label>
                    </span>
                  </b-col>
                </div>
                <div class="border-bottom border-light py-3">
                  <b-col md="12">
                    <p class="font-weight-bolder text-dark mb-2">
                      Simplificado
                    </p>
                    <span class="switch switch-icon">
                      <label v-b-tooltip.hover.right="'Não/Sim'">
                        <input
                          type="checkbox"
                          v-model="contas_pagar_simplificado"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-col>
                </div>
              </b-row>
              <button
                @click="gerarContasPagar()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Cobranças:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_cobranca"
                    label-for="data_inicial_cobranca"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_cobranca">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_cobranca"
                    label-for="data_final_cobranca"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_cobranca">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarCobrancas()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Movimento Caixa</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Movimento Caixa:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_movimento"
                    label-for="data_inicial_movimento"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_movimento">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_movimento"
                    label-for="data_final_movimento"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_movimento">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <div class="border-bottom border-light py-3">
                  <b-col md="12">
                    <p class="font-weight-bolder text-dark mb-2">
                      Considerar vendas do período informado
                    </p>
                    <span class="switch switch-icon">
                      <label v-b-tooltip.hover.right="'Não/Sim'">
                        <input type="checkbox" v-model="considerar_vendas" />
                        <span></span>
                      </label>
                    </span>
                  </b-col>
                </div>
              </b-row>
              <button
                @click="gerarMovimentoCaixa()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Comissões</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Comissão por vendedor:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_comissao"
                    label-for="data_inicial_comissao"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_comissao">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_comissao"
                    label-for="data_final_comissao"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_comissao">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group label="Vendedor" label-for="vendedor">
                    <VueSelect
                      ref="buscaVendedor"
                      :filterable="false"
                      @input="setVendedor"
                      :options="vendedores"
                      v-model="vendedor"
                      @search="onSearchVendedor"
                    >
                      <template slot="no-options">
                        Informe o nome do vendedor
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarComissao()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import swal from "sweetalert";
import _ from "lodash";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "relatorios-financeiro",
  mixins: [SistemaMixin, PessoaMixin],
  data() {
    return {
      data_inicial: null,
      data_final: null,
      data_inicial_pagar: null,
      data_final_pagar: null,
      data_inicial_movimento: null,
      data_final_movimento: null,
      reportTarge: null,
      data_final_comissao: null,
      data_inicial_comissao: null,
      data_inicial_cobranca: null,
      data_final_cobranca: null,
      vendedor: null,
      vendedores: [],
      statusSelecionadoReceber: "Todos",
      statusSelecionadoPagar: "Todos",
      statusContaReceber: [
        {
          id: 1,
          descricao: "Vencida",
        },
        {
          id: 2,
          descricao: "A receber",
        },
        {
          id: 3,
          descricao: "Recebida",
        },
        {
          id: 4,
          descricao: "Todos",
        },
      ],
      statusContaPagar: [
        {
          id: 1,
          descricao: "Paga",
        },
        {
          id: 2,
          descricao: "Atrasada",
        },
        {
          id: 3,
          descricao: "Pendente",
        },
        {
          id: 4,
          descricao: "Todos",
        },
      ],
      data_recebimento: false,
      data_pagamento: false,
      considerar_vendas: false,
      contas_pagar_simplificado: false,
    };
  },
  components: {
    VueSelect,
  },
  watch: {
    reportTarge(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.Echo.leaveChannel(
          "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
        );
      }
    },
  },
  methods: {
    async getDataInit() {},
    gerarContasReceber() {
      if (!this.data_inicial || !this.data_final) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/receitas", {
            data_inicial: this.data_inicial,
            data_final: this.data_final,
            status: this.statusSelecionadoReceber ?? null,
            data_recebimento: this.data_recebimento ?? null,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarContasPagar() {
      if (!this.data_inicial_pagar || !this.data_final_pagar) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/despesas", {
            data_inicial: this.data_inicial_pagar,
            data_final: this.data_final_pagar,
            status: this.statusSelecionadoPagar ?? null,
            data_pagamento: this.data_pagamento ?? null,
            contas_pagar_simplificado: this.contas_pagar_simplificado ?? false,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarMovimentoCaixa() {
      if (!this.data_inicial_movimento || !this.data_final_movimento) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/movimento-caixa", {
            data_inicial: this.data_inicial_movimento,
            data_final: this.data_final_movimento,
            considerar_vendas: this.considerar_vendas,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    setVendedor(vendedor) {
      this.vendedor = vendedor;
    },
    gerarCobrancas() {
      if (!this.data_inicial_cobranca || !this.data_final_cobranca) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/cobrancas", {
            data_inicial: this.data_inicial_cobranca,
            data_final: this.data_final_cobranca,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarComissao() {
      if (!this.data_inicial_comissao || !this.data_final_comissao) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }

      if (!this.vendedor) {
        this.$toast.warning("Selecione o vendedor!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/comissao", {
            data_inicial: this.data_inicial_comissao,
            data_final: this.data_final_comissao,
            id_vendedor: this.vendedor.id_vendedor,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    onSearchVendedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchVendedor(loading, search, this);
      }
    },
    searchVendedor: _.debounce(async (loading, search, vm) => {
      vm.vendedores = await vm.getVendedorByNome(search);
      if (loading) loading(false);
    }, 350),
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    await this.getDataInit();
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
