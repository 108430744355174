<template>
  <div>
    <h4>OS</h4>
    <b-row>
      <b-col md="6">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Resumo das Ordens de Serviço:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_os"
                    label-for="data_inicial_os"
                    label="Data Inicial de entrada"
                  >
                    <b-form-input type="date" v-model="data_inicial_os">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_os"
                    label-for="data_final_os"
                    label="Data Final de entrada"
                  >
                    <b-form-input type="date" v-model="data_final_os">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Cliente" label-for="nome">
                    <VueSelect
                      ref="buscarCliente"
                      :filterable="false"
                      @input="setCliente"
                      :options="clientes"
                      @search="onSearchCliente"
                      v-model="cliente_os"
                    >
                      <template slot="no-options">
                        Informe o nome do cliente
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ option.nome }}
                          </div>
                          <span class="text-muted">{{
                            formatCpfCnpj(option.cnpj_cpf)
                          }}</span>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.label }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Técnico" label-for="nome">
                    <VueSelect
                      ref="buscarTecnico"
                      :filterable="false"
                      @input="setTecnico"
                      :options="tecnicos"
                      @search="onSearchTecnico"
                      v-model="tecnico_os"
                    >
                      <template slot="no-options">
                        Informe o nome do técnico
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ option.nome }}
                          </div>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.label }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Objeto" label-for="nome">
                    <VueSelect
                      ref="buscarObjeto"
                      :filterable="false"
                      @input="setObjeto"
                      :options="objetos"
                      @search="onSearchObjeto"
                      v-model="objeto_os"
                    >
                      <template slot="no-options">
                        Informe a descrição do objeto
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ option.descricao }}
                          </div>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.label }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Selecione o status" label-for="status">
                    <VueSelect
                      ref="buscarStatus"
                      :filterable="false"
                      :options="osStatus"
                      v-model="statusSelectedOS"
                    >
                      <template slot="no-options"> Informe o status </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarResumoOS()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import swal from "sweetalert";
import _ from "lodash";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mask } from "vue-the-mask";

export default {
  name: "relatorios-vendas",
  mixins: [SistemaMixin, PessoaMixin],
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      reportTarge: null,
      data_inicial_os: null,
      data_final_os: null,
      clienteSelectedOS: null,
      tecnicoSelectedOS: null,
      objetoSelectedOS: null,
      cliente_os: null,
      tecnico_os: null,
      objeto_os: null,
      clientes: [],
      osStatus: [],
      objetos: [],
      statusSelectedOS: null,
      tecnicos: [],
    };
  },
  watch: {
    reportTarge(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.Echo.leaveChannel(
          "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
        );
      }
    },
  },
  methods: {
    async getDataInit() {
      const { data } = await this.$http.post("/ordemservico/status");
      this.osStatus = data;
    },
    onSearchObjeto(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchObjeto(loading, search, this);
      }
    },
    searchObjeto: _.debounce(async (loading, search, vm) => {
      vm.objetos = await vm.getObjeto(search);
      loading(false);
    }, 500),
    onSearchTecnico(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchTecnico(loading, search, this);
      }
    },
    searchTecnico: _.debounce(async (loading, search, vm) => {
      vm.tecnicos = await vm.getTecnicos(search);
      loading(false);
    }, 500),
    onSearchCliente(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchCliente(loading, search, this);
      }
    },
    searchCliente: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getClientes(search);
      loading(false);
    }, 500),
    setCliente(cliente) {
      if (!cliente) return;
      this.cliente_os = cliente.nome;
      this.clienteSelectedOS = cliente;
    },
    setTecnico(tecnico) {
      if (!tecnico) return;
      this.tecnico_os = tecnico.nome;
      this.tecnicoSelectedOS = tecnico;
    },
    setObjeto(objeto) {
      if (!objeto) return;
      this.objeto_os = objeto.descricao;
      this.objetoSelectedOS = objeto;
    },
    gerarResumoOS() {
      if (!this.data_inicial_os || !this.data_final_os) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/os", {
            data_inicial: this.data_inicial_os,
            data_final: this.data_final_os,
            id_pessoa: this.clienteSelectedOS?.id_pessoa ?? null,
            id_tecnico: this.tecnicoSelectedOS?.id_tecnico ?? null,
            status: this.statusSelectedOS?.id ?? null,
            id_objeto: this.objetoSelectedOS?.id_objeto ?? null,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    await this.getDataInit();
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
