import kit from "@/modules/Kit/pages/Index";
import kitNovo from "@/modules/Kit/pages/Create";

export default [
  {
    name: "kit",
    path: "/kit",
    component: kit,
    meta: { title: "Kit - Nuts" },
  },
  {
    path: "/kit/novo",
    name: "kitNovo",
    component: kitNovo,
    meta: { title: "Kit - Nuts" },
  },
];
