import Vue from "vue";
import axios from "axios";
import VueToast from "vue-toast-notification";
import JwtService from "@/core/services/jwt.service";
import { SYSTEM_VARIABLE } from "../modules/Application";

Vue.use(VueToast, {
  position: "top-right",
  duration: 8000
});

export const httpResetLogin = axios.create({
  baseURL: `${SYSTEM_VARIABLE.BASE_URL_API}/api`,
  headers: {
    Accept: "application/json"
  }
});

export const http = axios.create({
  baseURL: `${SYSTEM_VARIABLE.BASE_URL_API}/api`,
  headers: {
    Accept: "application/json"
  }
});

http.interceptors.request.use(async config => ({
  ...config,
  headers: {
    ...config.headers,
    AuthorizationType: `Bearer`,
    Authorization: `Bearer ` + (await JwtService.getToken())
  }
}));

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status == 401) {
      Vue.$toast.error(`É necessário estar logado!`);
      window.location.href = `/#/login`;
    }

    if (error.response.data.message) {
      Vue.$toast.error(error.response.data.message);
    }

    if (error.response.data.msg) {
      Vue.$toast.error(error.response.data.msg);
    }

    if (error.response.data.errors) {
      const errors = Object.entries(error.response.data.errors);

      errors.forEach(element => {
        element[1].forEach(e => {
          Vue.$toast.error(e);
        });
      });
    }
    return Promise.reject(error);
  }
);

httpResetLogin.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.data.msg) {
      Vue.$toast.error(error.response.data.msg);
    }

    if (error.response.data.errors) {
      const errors = Object.entries(error.response.data.errors);

      errors.forEach(element => {
        element[1].forEach(e => {
          Vue.$toast.error(e);
        });
      });
    }
    return Promise.reject(error);
  }
);
