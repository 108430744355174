<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Número',
          field: 'numero',
        },
        {
          type: 'text',
          label: 'Produto',
          field: 'produto',
        },
        {
          type: 'text',
          label: 'Responsável',
          field: 'responsavel',
        },
        {
          type: 'date',
          label: 'Dt. Término',
          field: 'data_termino',
        },
        {
          type: 'date',
          label: 'Dt. Inicio',
          field: 'data_inicio',
        },
        {
          type: 'date',
          label: 'Dt. Previsão',
          field: 'data_previsao',
        },
        {
          type: 'date',
          label: 'Dt. Previsão término',
          field: 'data_previsao_termino',
        },
        {
          type: 'select',
          label: 'Status',
          field: 'status',
          options: [
            'Não Iniciado',
            'Em produção',
            'Atrasado',
            'Pausado',
            'Entrega Parcial',
            'Finalizado',
          ],
        },
      ]"
    />

    <TableApp
      titulo="Ordem de Produção"
      acaoBtn="/#/producao-nova"
      textoBtn="Nova Produção"
      api-url="/producao/lista"
      :fields="fields"
      classFilterColumn="numero"
      ref="producaoList"
      :perPage="10"
    >
      <template slot="customRow" scope="props">
        <span slot="numero" class="pl-0 pt-8" v-if="props.field == 'numero'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ props.rowData.numero }}
            </div>
          </div>
        </span>
        <span slot="produto" class="pl-0 pt-8" v-if="props.field == 'produto'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ props.rowData.produto.descricao }}
            </div>
          </div>
        </span>
        <span
          slot="responsavel"
          class="pl-0 pt-8"
          v-if="props.field == 'responsavel'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ props.rowData.responsavel }}
            </div>
          </div>
        </span>
        <div
          slot="data_termino"
          class="d-flex align-items-center justify-content-end font-weight-bold"
          v-if="props.field == 'data_termino'"
        >
          {{ props.rowData.data_termino | moment("DD/MM/YYYY") }}
        </div>
        <div
          slot="data_inicio"
          class="d-flex align-items-center justify-content-end font-weight-bold"
          v-if="props.field == 'data_inicio'"
        >
          {{ props.rowData.data_inicio | moment("DD/MM/YYYY") }}
        </div>
        <div
          slot="data_previsao"
          class="d-flex align-items-center justify-content-end font-weight-bold"
          v-if="props.field == 'data_previsao'"
        >
          {{ props.rowData.data_previsao | moment("DD/MM/YYYY") }}
        </div>
        <div
          slot="data_previsao_termino"
          class="d-flex align-items-center justify-content-end font-weight-bold"
          v-if="props.field == 'data_previsao_termino'"
        >
          {{ props.rowData.data_previsao_termino | moment("DD/MM/YYYY") }}
        </div>
        <span
          slot="status"
          v-if="props.field == 'status'"
          :class="statusProducaoClass(props.rowData.status)"
        >
          {{ statusProducaoLabel(props.rowData.status) }}
        </span>
        <span
          slot="valorTotal"
          v-if="props.field == 'valorTotal'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg"
        >
          R$
          {{ numberToReal(props.rowData.valorTotal, 2) }}
        </span>
        <span
          slot="actions"
          v-if="props.field == 'actions'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg"
        >
          <div class="action-buttons">
            <button
              @click="imprimirOP(props.rowData)"
              v-b-tooltip.bottom="'Imprimir Ordem de Produção'"
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
            >
              <PdfIcon :size="22" />
            </button>
            <button
              @click="editarProd(props.rowData)"
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
              v-b-tooltip.bottom="'Editar Ordem de Produção'"
              v-if="props.rowData.status !== 6"
            >
              <PencilIcon :size="20" />
            </button>
            <button
              v-if="props.rowData.status != 6 && props.rowData.status != 5"
              @click="excluir(props.rowData)"
              class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
              v-b-tooltip.hover.bottom="'Excluir'"
            >
              <TrashCanIcon :size="16" />
            </button>
          </div>
        </span>
      </template>
    </TableApp>

    <SendToWppModal
      :data="dataToWhatsapp"
      :openModal="wppOpenModal"
      @handleOpenModal="(openModal) => (wppOpenModal = openModal)"
      module="os"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableApp from "@/components/Table/Table2";
import swal from "sweetalert";
import { SistemaMixin } from "@/modules/Application";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import PdfIcon from "vue-material-design-icons/FilePdfBox.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import "flatpickr/dist/themes/material_blue.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import FilterFields from "@/components/FilterFields";
import SendToWppModal from "@/components/SendToWppModal";
import { actionsStore } from "@/components/Table/actionsStore";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";

export default {
  name: "producaoLista",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    PdfIcon,
    PencilIcon,
    FilterFields,
    SendToWppModal,
    TrashCanIcon,
  },
  data() {
    return {
      actionsStore,
      components: {
        flatPickr,
      },
      wppNumberToSend: null,
      wppOpenModal: false,
      isLoading: false,
      userEmailsToSend: [],
      selectedEmail: null,
      nfeIdToSendEmail: null,
      ccEmail: null,
      dataToWhatsapp: null,
      osStatus: [],
      statusSelected: null,
      fields: [
        {
          label: "Número",
          field: "numero",
          type: "text",
          slot: true,
          sortable: false,
          width: "2%",
        },
        {
          label: "Produto",
          field: "produto",
          type: "text",
          slot: true,
          sortable: false,
          width: "15%",
        },
        {
          label: "Responsável",
          field: "responsavel",
          type: "text",
          slot: true,
          width: "15%",
          sortable: false,
        },
        {
          label: "Dt. Término",
          field: "data_termino",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Dt. Início",
          field: "data_inicio",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Dt. Previsão",
          field: "data_previsao",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Dt. Previsão Término",
          field: "data_previsao_termino",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Status",
          width: "15%",
          field: "status",
          sortable: true,
          slot: true,
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
        },
      ],
      numeroNota: null,
      nomeCliente: null,
      correcaoText: null,
      pdfCorrecaoUrl: null,
      xmlCorrecaoUrl: null,
      nfeId: null,
      form: {},
      serie: null,
      xmlFiles: [],
    };
  },
  methods: {
    async getDataInit() {
      const { data } = await this.$http.post("/ordemservico/status");
      this.osStatus = data;
    },
    imprimirOP(data) {
      swal({
        title: "Deseja gerar o pdf da Ordem de Produção?",
        text: "Aguarde, pois será gerado o pdf automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/producao/imprimir", {
            id_producao: data.id_producao,
          })
          .then((result) => {
            console.log(result.data);
            if (result.data) {
              window.open(result.data.url_producao, "_blank");
            }
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    async editarProd(prodData) {
      swal({
        title: "Deseja editar a Ordem de Produção?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        swal.stopLoading();
        swal.close();
        this.$toast.info("Você será redirecionado agora");
        this.$router.push({
          name: "producaoNova",
          params: { prodData },
        });
      });
    },
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir a produção número:  " + data.numero + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/producao/delete", {
              id_producao: data.id_producao,
            });

            this.$refs.producaoList.refresh();
            this.$toast.success("Ordem de produção excluída!");
          }
        });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  created() {
    this.getDataInit();
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    let inputs = ['input[placeholder="Data"]'];
    inputs.forEach(function (input) {
      flatPickr(input, {
        dateFormat: "Y-m-d",
        allowInput: true,
        locale: Portuguese,
      });
    });
  },
};
</script>

<style>
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #ee7361 !important;
}

.headerColumn {
  text-align: start;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #ee7361 !important;
  color: #ee7361 !important;
}
</style>
