import naturezaLista from "@/modules/Naturezas/pages/Index";
import naturezaNova from "@/modules/Naturezas/pages/Create";
import naturezaEditar from "@/modules/Naturezas/pages/Update";

export default [
  {
    name: "naturezasLista",
    path: "/naturezas",
    component: naturezaLista,
    meta: { title: "Naturezas - Nuts" },
  },
  {
    name: "naturezasNova",
    path: "/naturezas/nova",
    component: naturezaNova,
    meta: { title: "Naturezas - Nuts" },
  },
  {
    name: "naturezasEditar",
    path: "/naturezas/:id/editar",
    component: naturezaEditar,
    meta: { title: "Naturezas - Nuts" },
  },
];
