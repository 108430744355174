<template>
  <div>
    <h4>Vendas</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Resumo vendas:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_venda"
                    label-for="data_inicial_venda"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_venda">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_venda"
                    label-for="data_final_venda"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_venda">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Grupo" label-for="grupos">
                    <select
                      name="grupos"
                      id="grupos"
                      v-model="id_grupo_venda"
                      class="form-control"
                    >
                      <option
                        v-for="(g, index) in grupos"
                        :value="g.id"
                        :key="'grupos' + index"
                      >
                        {{ g.nome }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Cliente" label-for="nome">
                    <VueSelect
                      ref="buscarCliente"
                      :filterable="false"
                      @input="setClienteResumoVendas"
                      :options="clientes"
                      @search="onSearchCliente"
                      v-model="cliente_resumo_vendas"
                    >
                      <template slot="no-options">
                        Informe o nome do cliente
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ option.nome }} -
                            {{ formatCpfCnpj(option.cnpj_cpf) }}
                          </div>
                          <span class="text-muted">{{
                            formatCpfCnpj(option.cnpj_cpf)
                          }}</span>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.label }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="considerar_cfops"
                    label-for="considerar_cfops"
                    label="Considerar CFOPs de Remessa/Devolução"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setConsideraCFOP"
                          :checked="considerar_cfops == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="apresentar_custo_lucro"
                    label-for="apresentar_custo_lucro"
                    label="Não apresentar Custo/Lucro"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setApresentarCustoLucro"
                          :checked="n_apresentar_custo_lucro == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="informar_fornecedor"
                    label-for="informar_fornecedor"
                    label="Apresentar Fornecedor"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setFornecedorPrincipal"
                          :checked="informar_fornecedor == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="buscar_pela_referencia"
                    label-for="buscar_pela_referencia"
                    label="Buscar pela referência dos produtos"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setBuscarPelaReferencia"
                          :checked="buscar_pela_referencia == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarResumoVendas()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Vendas detalhado:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_vendadetalhada"
                    label-for="data_inicial_vendadetalhada"
                    label="Data Inicial"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_inicial_vendadetalhada"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_vendadetalhada"
                    label-for="data_final_vendadetalhada"
                    label="Data Final"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_final_vendadetalhada"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="considerar_cfops_detalhada"
                    label-for="considerar_cfops_detalhada"
                    label="Considerar CFOPs de Remessa/Devolução"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setConsideraCFOPDetalhada"
                          :checked="considerar_cfops_detalhada == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="gerar_piscofins"
                    label-for="gerar_piscofins"
                    label="PIS/COFINS"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setCofinsPis"
                          :checked="gerar_piscofins == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="considerar_cancelamentos"
                    label-for="considerar_cancelamentos"
                    label="Considerar vendas canceladas"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setCancelamentos"
                          :checked="considerar_cancelamentos == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="6">
                  <b-form-group
                    id="totalizar_por_dia"
                    label-for="totalizar_por_dia"
                    label="Totalizar por dia"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setTotalizarPorDia"
                          :checked="totalizar_por_dia == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col> -->
                <b-col md="12">
                  <b-form-group label="Selecione o modelo" label-for="modelos">
                    <multiselect
                      v-model="modelosSelecionados"
                      :options="modelos"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      placeholder="Digite o modelo"
                      label="descricao"
                      track-by="modelo"
                      :preselect-first="true"
                    >
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; isOpen"
                        ></span
                      ></template>
                    </multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarVendaDetalhada()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Resumo de vendas por NCM:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_ncm"
                    label-for="data_inicial_ncm"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_ncm">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_ncm"
                    label-for="data_final_ncm"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_ncm">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarVendaByNCM()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Resumo vendas Importadas:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_venda_importada"
                    label-for="data_inicial_venda_importada"
                    label="Data Inicial"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_inicial_venda_importada"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_venda_importada"
                    label-for="data_final_venda_importada"
                    label="Data Final"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_final_venda_importada"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarResumoVendasImportadas()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Resumo sintético das vendas:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_venda_sintetica"
                    label-for="data_inicial_venda_sintetica"
                    label="Data Inicial"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_inicial_venda_sintetica"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_venda_sintetica"
                    label-for="data_inicial_venda_sintetica"
                    label="Data Final"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_final_venda_sintetica"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarResumoVendasSinteticas()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Fiscal</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Sped:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_sped"
                    label-for="data_inicial_sped"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_sped">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_sped"
                    label-for="data_final_sped"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_sped">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="haveInventory"
                    label-for="haveInventory"
                    label="Gerar Inventário"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setInventario"
                          :checked="haveInventory == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-show="haveInventory">
                  <b-form-group
                    id="data_inventario"
                    label-for="data_inventario"
                    label="Data Base"
                  >
                    <b-form-input type="date" v-model="data_inventario">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="haveC170"
                    label-for="haveC170"
                    label="Gerar itens das Notas Eletrônicas (Registro C170)"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setC170"
                          :checked="haveC170 == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="haveK200"
                    label-for="haveK200"
                    label="Gerar Bloco K (Registro K200)"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setK200"
                          :checked="haveK200 == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Perfil" label-for="perfil_sped">
                    <select
                      name="perfil_sped"
                      id="perfil_sped"
                      v-model="perfil_sped"
                      class="form-control"
                    >
                      <option
                        v-for="(p, index) in perfilSped"
                        :value="p.tipo"
                        :key="'tipo' + index"
                      >
                        {{ p.tipo }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="sped_contribuicoes"
                    label-for="sped_contribuicoes"
                    label="Gerar Sped Contribuições"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setSpedContribuicoes"
                          :checked="sped_contribuicoes == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarSped()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Sintegra:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_sintegra"
                    label-for="data_inicial_sintegra"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_sintegra">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_sintegra"
                    label-for="data_final_sintegra"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_sintegra">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-show="inventarioSintegra">
                <b-col md="6">
                  <b-form-group
                    id="data_inventario"
                    label-for="data_inventario_sintegra"
                    label="Data Base Inventário"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_inventario_sintegra"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Valor ser considerado" label-for="tipo">
                    <select
                      name="finalidade"
                      id="finalidade"
                      v-model="tipo_inventario"
                      class="form-control"
                    >
                      <option
                        v-for="(t, index) in tipoInventarioSintegra"
                        :value="t.id"
                        :key="'tipo' + index"
                      >
                        {{ t.descricao }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Registros Sintegra"
                    label-for="registros"
                  >
                    <multiselect
                      v-model="registrosSelecionados"
                      :options="registros"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      placeholder="Digite o registro"
                      label="descricao"
                      track-by="descricao"
                      :preselect-first="true"
                    >
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; isOpen"
                        ></span
                      ></template>
                    </multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Finalidade Sintegra"
                    label-for="finalidade"
                  >
                    <select
                      name="finalidade"
                      id="finalidade"
                      v-model="finalidade"
                      class="form-control"
                    >
                      <option
                        v-for="(f, index) in finalidades"
                        :value="f.id"
                        :key="'finalidade' + index"
                      >
                        {{ f.descricao }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    id="remove_base_icms"
                    label-for="remove_base_icms"
                    label="Não considerar Base de ICMS das compras"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setZerarBaseIcms"
                          :checked="remove_base_icms == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarSintegra()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>XML das vendas:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_xml"
                    label-for="data_inicial_xml"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_xml">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_xml"
                    label-for="data_final_xml"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_xml">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    id="email_xml"
                    label-for="email_xml"
                    label="E-mail para receber os xmls"
                  >
                    <b-form-input type="email" v-model="email_xml">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="considerar_canceladas_inutilizadas"
                    label-for="considerar_canceladas_inutilizadas"
                    label="Considerar vendas canceladas/inutilizadas"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setConsiderarVendasCanceladas"
                          :checked="considerar_canceladas_inutilizadas == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="desconsiderar_remessas_devolucao"
                    label-for="desconsiderar_remessas_devolucao"
                    label="Desconsiderar CFOPs de Remessa/Devolução"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setDesconsiderarRmessaDevolucao"
                          :checked="desconsiderar_remessas_devolucao == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarXml()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Compras</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Compras:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_compras"
                    label-for="data_inicial_compras"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_compras">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_compras"
                    label-for="data_final_compras"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_compras">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <VueSelect
                    placeholder="Informe o nome do fornecedor"
                    ref="buscarFornecedor"
                    label="id_pessoa"
                    :filterable="false"
                    @input="setFornecedor"
                    v-model="valueFornecedor"
                    :options="fornecedores"
                    @search="onSearchFornecedor"
                  >
                    <template slot="no-options">
                      Informe o nome do fornecedor
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </VueSelect>
                </b-col>
              </b-row>
              <br />
              <button
                @click="gerarCompras()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Resumo de Compras:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_resumocompras"
                    label-for="data_inicial_resumocompras"
                    label="Data Inicial"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_inicial_resumocompras"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_resumocompras"
                    label-for="data_final_resumocompras"
                    label="Data Final"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_final_resumocompras"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <VueSelect
                    placeholder="Informe o nome do fornecedor"
                    ref="buscarFornecedor"
                    label="id_pessoa"
                    :filterable="false"
                    @input="setFornecedorResumoCompras"
                    v-model="valueFornecedorResumoCompras"
                    :options="fornecedores"
                    @search="onSearchFornecedor"
                  >
                    <template slot="no-options">
                      Informe o nome do fornecedor
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </VueSelect>
                </b-col>
              </b-row>
              <br />
              <button
                @click="gerarResumoCompras()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>XML das compras:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_xml_compra"
                    label-for="data_inicial_xml_compra"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_xml_compra">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_xml_compra"
                    label-for="data_final_xml_compra"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_xml_compra">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    id="email_xml_compra"
                    label-for="email_xml_compra"
                    label="E-mail para receber os xmls"
                  >
                    <b-form-input type="email" v-model="email_xml_compra">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarXmlCompras()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Orçamentos</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Orçamentos:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_orcamento"
                    label-for="data_inicial_orcamento"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_orcamento">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_orcamento"
                    label-for="data_final_orcamento"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_orcamento">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Cliente" label-for="nome">
                    <VueSelect
                      ref="buscarCliente"
                      :filterable="false"
                      @input="setCliente"
                      :options="clientes"
                      @search="onSearchCliente"
                      v-model="cliente_orcamento"
                    >
                      <template slot="no-options">
                        Informe o nome do cliente
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ option.nome }}
                          </div>
                          <span class="text-muted">{{
                            formatCpfCnpj(option.cnpj_cpf)
                          }}</span>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.label }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarOrcamento()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Produtos Orçados:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_produto_orcado"
                    label-for="data_inicial_produto_orcado"
                    label="Data Inicial"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_inicial_produto_orcado"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_produto_orcado"
                    label-for="data_final_produto_orcado"
                    label="Data Final"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_final_produto_orcado"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Cliente" label-for="nome">
                    <VueSelect
                      ref="buscarCliente"
                      :filterable="false"
                      @input="setClienteProdutoOrcado"
                      :options="clientes"
                      @search="onSearchCliente"
                      v-model="cliente_produto_orcado"
                    >
                      <template slot="no-options">
                        Informe o nome do cliente
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ option.nome }}
                          </div>
                          <span class="text-muted">{{
                            formatCpfCnpj(option.cnpj_cpf)
                          }}</span>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.label }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarProdutoOrcado()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import swal from "sweetalert";
import Multiselect from "vue-multiselect";
import _ from "lodash";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mask } from "vue-the-mask";

export default {
  name: "relatorios-vendas",
  mixins: [SistemaMixin, PessoaMixin],
  components: {
    Multiselect,
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      reportTarge: null,
      data_inicial_venda: null,
      data_final_venda: null,
      data_inicial_venda_importada: null,
      data_final_venda_importada: null,
      data_final_vendadetalhada: null,
      data_inicial_vendadetalhada: null,
      data_inicial_ncm: null,
      data_final_ncm: null,
      data_limite: null,
      livro: null,
      data_inicial_sped: null,
      data_final_sped: null,
      haveInventory: false,
      data_inventario: null,
      haveC170: false,
      haveK200: false,
      data_inicial_compras: null,
      data_final_compras: null,
      data_inicial_resumocompras: null,
      data_final_resumocompras: null,
      data_inicial_sintegra: null,
      data_final_sintegra: null,
      data_inventario_sintegra: null,
      finalidade: 1,
      finalidades: [],
      registros: [],
      registrosSelecionados: [],
      inventarioSintegra: false,
      tipoInventarioSintegra: [],
      tipo_inventario: null,
      data_inicial_xml: null,
      data_final_xml: null,
      email_xml: null,
      data_inicial_orcamento: null,
      data_final_orcamento: null,
      cliente_orcamento: null,
      clienteSelected: null,
      clientes: [],
      grupos: [],
      id_grupo_venda: null,
      considerar_cfops: false,
      considerar_cfops_detalhada: false,
      fornecedores: [],
      valueFornecedor: null,
      valueFornecedorResumoCompras: null,
      considerar_canceladas_inutilizadas: true,
      data_final_produto_orcado: null,
      data_inicial_produto_orcado: null,
      cliente_produto_orcado: null,
      clienteSelectedProdutoOrcado: null,
      data_inicial_venda_sintetica: null,
      data_final_venda_sintetica: null,
      gerar_piscofins: false,
      perfilSped: [
        {
          tipo: "A",
        },
        {
          tipo: "B",
        },
        {
          tipo: "C",
        },
      ],
      considerar_cancelamentos: false,
      cliente_resumo_vendas: null,
      clienteSelectedResumoVendas: null,
      n_apresentar_custo_lucro: false,
      modelos: [
        {
          descricao: "Todos",
          modelo: 78,
        },
        {
          descricao: "NFe",
          modelo: 55,
        },
        {
          descricao: "NFCe",
          modelo: 65,
        },
        {
          descricao: "Pré-Venda",
          modelo: 77,
        },
      ],
      modelosSelecionados: [],
      buscar_pela_referencia: false,
      informar_fornecedor: false,
      remove_base_icms: false,
      desconsiderar_remessas_devolucao: false,
      data_inicial_xml_compra: null,
      data_final_xml_compra: null,
      email_xml_compra: null,
      sped_contribuicoes: false,
    };
  },
  watch: {
    reportTarge(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.Echo.leaveChannel(
          "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
        );
      }
    },
    registrosSelecionados(newValue, oldValue) {
      if (newValue !== oldValue) {
        newValue.forEach((value) => {
          if (value.descricao == "Registro 74 - Inventário") {
            this.inventarioSintegra = true;
          }
        });
      }
    },
  },
  methods: {
    async getDataInit() {
      this.finalidades = await this.getFinalidadeSintegra();
      this.registros = await this.getRegistrosSintegra();
      this.tipoInventarioSintegra = await this.getTipoInventarioSintegra();
      this.getGrupos().then((data) => (this.grupos = data));
    },
    onSearchCliente(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchCliente(loading, search, this);
      }
    },
    searchCliente: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getClientes(search);
      loading(false);
    }, 500),
    setCliente(cliente) {
      if (!cliente) return;
      this.cliente_orcamento = cliente.nome;
      this.clienteSelected = cliente;
    },
    setClienteProdutoOrcado(cliente) {
      if (!cliente) return;
      this.cliente_produto_orcado = cliente.nome;
      this.clienteSelectedProdutoOrcado = cliente;
    },
    setClienteResumoVendas(cliente) {
      if (!cliente) return;
      this.cliente_resumo_vendas = cliente.nome;
      this.clienteSelectedResumoVendas = cliente;
    },
    gerarOrcamento() {
      if (!this.data_inicial_orcamento || !this.data_final_orcamento) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/orcamentos", {
            data_inicial: this.data_inicial_orcamento,
            data_final: this.data_final_orcamento,
            id_pessoa: this.clienteSelected?.id_pessoa ?? null,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarProdutoOrcado() {
      if (
        !this.data_inicial_produto_orcado ||
        !this.data_final_produto_orcado
      ) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/orcamentos-detalhado", {
            data_inicial: this.data_inicial_produto_orcado,
            data_final: this.data_final_produto_orcado,
            id_pessoa: this.clienteSelectedProdutoOrcado?.id_pessoa ?? null,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarVendaByNCM() {
      if (!this.data_inicial_ncm || !this.data_final_ncm) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/total-by-ncm", {
            data_inicial: this.data_inicial_ncm,
            data_final: this.data_final_ncm,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarResumoVendasSinteticas() {
      if (
        !this.data_inicial_venda_sintetica ||
        !this.data_final_venda_sintetica
      ) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/sintetico-vendas", {
            data_inicial: this.data_inicial_venda_sintetica,
            data_final: this.data_final_venda_sintetica,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarResumoVendasImportadas() {
      if (
        !this.data_inicial_venda_importada ||
        !this.data_final_venda_importada
      ) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/vendas-importadas", {
            data_inicial: this.data_inicial_venda_importada,
            data_final: this.data_final_venda_importada,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarResumoVendas() {
      if (!this.data_inicial_venda || !this.data_final_venda) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/resumo-vendas", {
            data_inicial: this.data_inicial_venda,
            data_final: this.data_final_venda,
            id_grupo_venda: this.id_grupo_venda ?? null,
            considerar_cfops: this.considerar_cfops ?? null,
            cnpj_cpf: this.clienteSelectedResumoVendas?.cnpj_cpf ?? null,
            n_apresentar_custo_lucro: this.n_apresentar_custo_lucro,
            cliente: this.clienteSelectedResumoVendas?.nome ?? null,
            buscar_pela_referencia: this.buscar_pela_referencia ?? false,
            informar_fornecedor: this.informar_fornecedor ?? false,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarVendaDetalhada() {
      if (
        !this.data_inicial_vendadetalhada ||
        !this.data_final_vendadetalhada
      ) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/vendas", {
            data_inicial: this.data_inicial_vendadetalhada,
            data_final: this.data_final_vendadetalhada,
            considerar_cfops: this.considerar_cfops_detalhada ?? null,
            gerar_piscofins: this.gerar_piscofins ?? null,
            considerar_cancelamentos: this.considerar_cancelamentos ?? null,
            modelos: this.modelosSelecionados,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarSintegra() {
      if (!this.data_inicial_sintegra && !this.data_final_sintegra) {
        this.$toast.warning("Informe as datas para gerar o sintegra!");
        return;
      }

      if (!this.registrosSelecionados) {
        this.$toast.warning("Informe os registros para gerar o sintegra!");
        return;
      }
      swal({
        title: "Deseja realizar a geração do Sintegra?",
        text: "Aguarde, pois será notificado no menu quando o relátorio foi concluído!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/sintegra", {
            data_inicial: this.data_inicial_sintegra,
            data_final: this.data_final_sintegra,
            registros: this.registrosSelecionados,
            data_inventario: this.data_inventario_sintegra,
            finalidade: this.finalidade,
            tipo_inventario: this.tipo_inventario,
            base_icms: this.base_icms ?? false,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarSped() {
      if (!this.data_inicial_sped && !this.data_final_sped) {
        this.$toast.warning("Informe as datas para gerar o sped!");
        return;
      }

      let url = "/relatorios/sped";

      if (this.sped_contribuicoes) {
        url = "relatorios/sped-contribuicoes";
      }

      swal({
        title: "Deseja realizar a geração do Sped Fiscal?",
        text: "Aguarde, pois será notificado no menu quando o relátorio foi concluído!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post(url, {
            data_inicial: this.data_inicial_sped,
            data_final: this.data_final_sped,
            haveInventory: this.haveInventory,
            data_inventario: this.data_inventario,
            haveC170: this.haveC170,
            haveK200: this.haveK200,
            perfil_sped: this.perfil_sped ?? "B",
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    setFornecedorPrincipal() {
      this.informar_fornecedor =
        this.informar_fornecedor == true ? false : true;
    },
    setTotalizarPorDia() {
      this.totalizar_por_dia = this.totalizar_por_dia == true ? false : true;
    },
    setCancelamentos() {
      this.considerar_cancelamentos =
        this.considerar_cancelamentos == true ? false : true;
    },
    setCofinsPis() {
      this.gerar_piscofins = this.gerar_piscofins == true ? false : true;
    },
    setConsideraCFOP() {
      this.considerar_cfops = this.considerar_cfops == true ? false : true;
    },
    setConsideraCFOPDetalhada() {
      this.considerar_cfops_detalhada =
        this.considerar_cfops_detalhada == true ? false : true;
    },
    setInventario() {
      this.haveInventory = this.haveInventory == true ? false : true;
    },
    setC170() {
      this.haveC170 = this.haveC170 == true ? false : true;
    },
    setK200() {
      this.haveK200 = this.haveK200 == true ? false : true;
    },
    setSpedContribuicoes() {
      this.sped_contribuicoes = this.sped_contribuicoes == true ? false : true;
    },
    setConsiderarVendasCanceladas() {
      this.considerar_canceladas_inutilizadas =
        this.considerar_canceladas_inutilizadas == true ? false : true;
    },
    setApresentarCustoLucro() {
      this.n_apresentar_custo_lucro =
        this.n_apresentar_custo_lucro == true ? false : true;
    },
    setBuscarPelaReferencia() {
      this.buscar_pela_referencia =
        this.buscar_pela_referencia == true ? false : true;
    },
    setZerarBaseIcms() {
      this.remove_base_icms = this.remove_base_icms == true ? false : true;
    },
    setDesconsiderarRmessaDevolucao() {
      this.desconsiderar_remessas_devolucao =
        this.desconsiderar_remessas_devolucao == true ? false : true;
    },
    gerarCompras() {
      if (!this.data_inicial_compras || !this.data_final_compras) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio foi concluído!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/compras", {
            data_inicial: this.data_inicial_compras,
            data_final: this.data_final_compras,
            fornecedor: this.valueFornecedor?.id_pessoa ?? null,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarResumoCompras() {
      if (!this.data_inicial_resumocompras || !this.data_final_resumocompras) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio foi concluído!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/resumo-compras", {
            data_inicial: this.data_inicial_resumocompras,
            data_final: this.data_final_resumocompras,
            fornecedor: this.valueFornecedorResumoCompras?.id_pessoa ?? null,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarXml() {
      if (!this.data_inicial_xml || !this.data_final_xml) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a geração do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio foi concluído!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/get-xml", {
            data_inicial: this.data_inicial_xml,
            data_final: this.data_final_xml,
            email: this.email_xml,
            considerar_canceladas_inutilizadas:
              this.considerar_canceladas_inutilizadas ?? false,
            desconsiderar_remessas_devolucao:
              this.desconsiderar_remessas_devolucao ?? false,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarXmlCompras() {
      if (!this.data_inicial_xml_compra || !this.data_final_xml_compra) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a geração do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio foi concluído!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Sair",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/get-compras-xml", {
            data_inicial: this.data_inicial_xml_compra,
            data_final: this.data_final_xml_compra,
            email: this.email_xml_compra,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    setFornecedorResumoCompras(fornecedor) {
      this.valueFornecedorResumoCompras = fornecedor;
    },
    setFornecedor(fornecedor) {
      this.valueFornecedor = fornecedor;
    },
    onSearchFornecedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchFornecedor(loading, search, this);
      }
    },
    searchFornecedor: _.debounce(async (loading, search, vm) => {
      vm.fornecedores = await vm.getFornecedorNome(search);
      loading(false);
    }, 350),
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    await this.getDataInit();
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
