<template>
  <div class="card-form container" v-if="dataFetched">
    <b-row :class="`mb-5 d-flex justify-content-around`" :style="cardStyle">
      <div>
        <img alt="Logo" :src="emissor.logo" class="max-h-80px" />
      </div>
      <div class="mt-5" style="float: right; text-align: right">
        <span>{{ emissor.nome }} - {{ emissor.telefone }}</span> <br />
        <span v-if="emissor.cnpj_cpf.length < 14"
          >CPF: {{ formatCpfCnpj(emissor.cnpj_cpf) }}</span
        >
        <span v-if="emissor.cnpj_cpf.length >= 14"
          >CNPJ: {{ formatCpfCnpj(emissor.cnpj_cpf) }}</span
        >
        <br />
        <span>{{
          `${emissor.logradouro} - ${emissor.numero} - ${emissor.bairro}, ${emissor.cep}`
        }}</span
        ><br />
        <span>{{ `${emissor.cidade} - ${emissor.uf}` }}</span>
        <br />
      </div>
    </b-row>

    <strong>OS: </strong><span> {{ numero }} </span>
    <div v-if="!finished">
      <div v-if="!isAnswered">
        <b-tabs class="mt-3">
          <b-tab title="Gerais" active>
            <b-card
              title="Cliente"
              tag="article"
              class="my-2 border border-primary"
              :style="cardStyle"
            >
              <b-card-text>
                <strong>Nome: </strong>{{ cliente.nome }}<br />
                <strong
                  v-if="
                    cliente.cnpj_cpf !== null && cliente.cnpj_cpf.length < 14
                  "
                  >CPF: {{ formatCpfCnpj(cliente.cnpj_cpf) }} <br />
                </strong>
                <strong
                  v-if="
                    cliente.cnpj_cpf !== null && cliente.cnpj_cpf.length >= 14
                  "
                  >CNPJ: {{ formatCpfCnpj(cliente.cnpj_cpf) }}<br />
                </strong>
                <strong v-if="cliente.telefone !== null">Telefone:</strong
                >{{ cliente.telefone }}<br />
                <div style="white-space: normal" v-if="cliente.cep !== null">
                  <strong>Endereço: </strong>
                  {{ cliente.logradouro }}, {{ cliente.numero }}, {{
                    cliente.bairro
                  }}, {{ cliente.complemento }} {{ cliente.cep }}, {{
                    cliente.cidade
                  }}-{{ cliente.uf }}
                </div>
              </b-card-text>
            </b-card>
            <b-card
              v-if="identificadores.length > 0"
              title="Objeto e Identificadores"
              tag="article"
              class="my-2 border border-primary"
              :style="cardStyle"
            >
              <b-card-text>
                <strong>Objeto: </strong>{{ objeto?.descricao }}<br />
                <div
                  v-for="identificador in identificadores"
                  :key="identificador?.descricao"
                >
                  <strong>{{ identificador?.descricao }}: </strong>
                  {{ identificador?.value }}
                </div>
              </b-card-text>
            </b-card>
            <b-card
              v-if="produtos.length > 0"
              title="Produtos"
              tag="article"
              class="mb-2 border border-primary"
              :style="cardStyle"
            >
              <b-card-text>
                <b-table
                  v-if="produtos.length > 0"
                  striped
                  small
                  hover
                  :items="tableItems"
                />
              </b-card-text>
            </b-card>
            <b-card
              v-if="servicos.length > 0"
              title="Serviços"
              tag="article"
              class="mb-2 border border-primary"
              :style="cardStyle"
            >
              <b-card-text
                v-for="(servico, index) in servicos"
                :key="servico.descricao"
              >
                <label>{{ servico.discriminacao }}</label>
                <b-table
                  striped
                  small
                  hover
                  :items="tableServicoItems(index)"
                />
              </b-card-text>
            </b-card>
            <b-card
              title="Pagamentos"
              tag="article"
              class="mb-2 border border-primary"
              :style="cardStyle"
              v-if="pagamentos"
            >
              <b-card-text
                v-for="(pagamento, index) in pagamentos"
                :key="pagamento.descricao"
              >
                <label>{{ pagamento.forma_pagamento.descricao }}</label>
                <b-table
                  v-if="pagamentos.length > 0"
                  striped
                  small
                  hover
                  :items="tablePagamentoItems(index)"
                />
              </b-card-text>
            </b-card>
            <b-card
              title="Outras informações"
              tag="article"
              class="my-2 border border-primary"
              :style="cardStyle"
            >
              <b-card-text>
                <strong>Observação: </strong> <div class="quebra-de-linha">{{ observacao }}</div> <br />
                <strong>Problema: </strong>
                {{ problema }}
                <br />
                <strong>Desconto Geral R$: </strong>
                {{ numberToReal(desconto, 2) }} <br />
                <strong>Total da OS R$: </strong> {{ numberToReal(valorTotal, 2) }}
                <br />
                <br />
                <strong>Data de entrada: </strong>
                {{ dataEntrada | moment("DD/MM/YYYY") }}
                <br />
                <strong>Data de saída: </strong>
                {{ dataSaida | moment("DD/MM/YYYY") }}
                <br />
                <strong>Data de previsão: </strong>
                {{ dataPrevisao | moment("DD/MM/YYYY") }}
                <br />
                <strong>Próxima revisão: </strong>
                {{ proximaRevisao | moment("DD/MM/YYYY") }}
                <br />
              </b-card-text>
            </b-card>
          </b-tab>
          <b-tab title="Anexos">
            <b-card
              title="Anexos"
              tag="article"
              class="mb-2 border border-primary"
              :style="cardStyle"
            >
              <b-card-text>
                <label>Anexo 1</label>
                <b-card-img
                  :src="anexo1"
                  class="rounded-0"
                  v-if="anexo1"
                  @click="openImage(anexo1)"
                  style="cursor: pointer"
                />
                <label>Anexo 2</label>
                <b-card-img
                  :src="anexo2"
                  class="rounded-0"
                  v-if="anexo2"
                  @click="openImage(anexo2)"
                  style="cursor: pointer"
                />
                <label>Anexo 3</label>
                <b-card-img
                  :src="anexo3"
                  class="rounded-0"
                  v-if="anexo3"
                  @click="openImage(anexo3)"
                  style="cursor: pointer"
                />
               <label>Anexo 4</label>
                <b-card-img
                  :src="anexo4"
                  class="rounded-0"
                  v-if="anexo4"
                  @click="openImage(anexo4)"
                  style="cursor: pointer"
                />
                <label>Anexo 5</label>
                <b-card-img
                  :src="anexo5"
                  class="rounded-0"
                  v-if="anexo5"
                  @click="openImage(anexo5)"
                  style="cursor: pointer"
                />
                <label>Anexo 6</label>
                <b-card-img
                  :src="anexo6"
                  class="rounded-0"
                  v-if="anexo6"
                  @click="openImage(anexo6)"
                  style="cursor: pointer"
                />
              </b-card-text>
            </b-card>
          </b-tab>
        </b-tabs>

        <span
          slot="status"
          v-if="status != 1 && status != 7 && status != 2 && status != 6"
          :class="statusClass(status)"
        >
          {{ statusLabel(status) }}, por {{ contatoResposta }} :
          {{ dataResposta | moment("DD/MM/YYYY HH:mm:ss") }}
        </span>
        <div
          class="mt-5 w-100 d-flex justify-content-center"
          v-if="status == 1 || status == 7 || status == 2 || status == 6"
        >
          <button
            @click="handleClientResponse(true)"
            class="btn btn-success btn-hover-primary mr-2"
            v-b-tooltip.hover.bottom="'Confirmar OS'"
          >
            Aprovar
          </button>
          <button
            @click="handleClientResponse(false)"
            class="btn btn-danger btn-hover-primary mr-2"
            v-b-tooltip.hover.bottom="'Rejeitar OS'"
          >
            Rejeitar
          </button>
        </div>
      </div>
      <div v-else>
        <b-card
          title="Confirmação"
          tag="article"
          class="my-2 border border-primary"
        >
          <b-form-group label="Status" label-for="status">
            <VueSelect
              ref="buscarStatus"
              :filterable="false"
              :options="allStatus"
              @search="onSearchStatus"
              v-model="statusClient"
            >
              <template slot="no-options"> Informe o status </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.descricao }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.descricao }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
          <b-form-group label="Nome do responsável" label-for="contato">
            <b-form-input
              id="contato"
              name="contato"
              v-model="contato"
              class="contato-input"
            />
          </b-form-group>
          <b-form-group
            :label="statusClient.id === 3 ? 'Observações' : 'Motivo'"
            label-for="motivo"
          >
            <v-textarea
              full-width
              no-resize
              rows="1"
              auto-grow
              v-model="motivo"
            ></v-textarea>
          </b-form-group>
          <div class="mt-5 w-100 d-flex justify-content-center">
            <button
              @click="handleSendResponse"
              class="btn btn-success btn-hover-primary mr-2"
              ref="submit"
              v-b-tooltip.hover.bottom="'Confirmar OS'"
            >
              Enviar
            </button>
          </div>
        </b-card>
      </div>
    </div>
    <div class="mt-10 w-100 d-flex justify-content-center" v-else>
      <strong>Obrigado por utilizar a Nuts!</strong>
    </div>
    <div class="mt-5 w-100 d-flex justify-content-end">
      <img
        alt="Logo"
        :src="layoutConfig('self.logo.secundary')"
        class="max-h-30px mr-5"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import moment from "moment";
import VueSelect from "vue-select";
import { debounce } from "lodash";

export default {
  name: "app-link-os",
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  directives: {
    mask,
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  components: {
    VueSelect,
  },
  data() {
    return {
      isAnswered: false,
      uuid: null,
      allStatus: [],
      dataFetched: false,
      cliente: null,
      dataEntrada: null,
      dataSaida: null,
      dataPrevisao: null,
      proximaRevisao: null,
      problema: null,
      emissor: null,
      observacao: null,
      pagamentos: [],
      produtos: [],
      servicos: [],
      objeto: null,
      identificadores: [],
      status: null,
      valorTotal: null,
      numero: null,
      desconto: null,
      despesas: null,
      statusClient: null,
      motivo: null,
      contato: null,
      finished: false,
      windowWidth: window.innerWidth,
      contatoResposta: null,
      dataResposta: null,
      tabIndex: 0,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
    tableItems() {
      return this.produtos.map((produto) => ({
        "código": produto.product?.codigo,
        nome: produto.product.descricao,
        qtd: produto.quantity,
        "un R$": this.numberToReal(produto.price, 2),
        "desconto R$": this.numberToReal(produto.discount, 2),
        "total R$": this.numberToReal(produto.totalPrice, 2),
      }));
    },
    isMobile() {
      return this.windowWidth < 569;
    },
    cardStyle() {
      return {
        width: this.isMobile ? "94vw" : "",
      };
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getInit() {
      const { data } = await this.$http.post("/ordemservico/lista-status");
      this.allStatus = data;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    openImage(imageUrl) {
      window.open(imageUrl, "_blank");
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    tablePagamentoItems(index) {
      return this.pagamentos[index].range.map((parcela, index) => ({
        parcela: index + 1,
        "Valor R$": this.numberToReal(parcela.value, 2),
        vencimento: moment(parcela.date).format("DD/MM/YYYY"),
      }));
    },
    tableServicoItems(index) {
      const servicoItems = [
        {
          quantidade: this.numberToReal(this.servicos[index].quantidade, 'quantidade'),
          "Un R$": this.numberToReal(this.servicos[index].unitario, 'preco'),
          "Total R$": this.numberToReal(this.servicos[index].total, 'preco'),
        },
      ];
      return servicoItems;
    },
    async handleSendResponse() {
      this.addLoadingToButton("submit");
      const { uuid } = this.$route.params;

      if (!this.statusClient) {
        this.$toast.error("Informe o status do OS!");
        this.removeLoadingToButton("submit");
        return;
      }

      if (this.statusClient.id === 3) {
        if (!this.contato || this.contato === "") {
          this.$toast.error("Obrigatório informar o nome do responsável");
          this.removeLoadingToButton("submit");
          return;
        }
        await this.$http.post("/ordemservico/update-os", {
          uuid,
          status: this.statusClient.id,
          motivo: this.motivo ?? "",
          contato: this.contato,
        });
        this.$toast.success("Resposta submetida!");
        this.finished = true;
        this.removeLoadingToButton("submit");
      } else {
        if (!this.contato || this.contato === "") {
          this.$toast.error("Obrigatório informar o nome do responsável");
          this.removeLoadingToButton("submit");
          return;
        }
        if (!this.motivo || this.motivo === "") {
          this.$toast.error("Informe o porque no campo observação");
          this.removeLoadingToButton("submit");
          return;
        }
        await this.$http.post("/ordemservico/update-os", {
          uuid,
          status: this.statusClient.id,
          motivo: this.motivo,
          contato: this.contato,
        });
        this.$toast.success("Resposta submetida!");
        this.finished = true;
        this.removeLoadingToButton("submit");
      }
    },
    async handleClientResponse(aproved) {
      this.isAnswered = true;
      this.statusClient = aproved ? this.allStatus[0] : this.allStatus[1];
    },
    onSearchStatus(search, loading) {
      if (search.length) {
        loading(true);
        this.searchStatus(loading, search, this);
      }
    },
    searchStatus: debounce(async (loading, search, vm) => {
      vm.allStatus = await vm.getClientes(search);
      loading(false);
    }, 350),
    statusClass(status) {
      switch (status) {
        case 1:
          return "label label-lg label-inline label-light-warning";
        case 2:
          return "label label-lg label-inline label-light-primary";
        case 3:
          return "label label-lg label-inline label-light-success";
        case 4:
          return "label label-lg label-inline label-light-danger";
        case 5:
          return "label label-lg label-inline label-light-success";
        case 6:
          return "label label-lg label-inline label-light-danger";
        case 7:
          return "label label-lg label-inline label-light-primary";
        case 8:
          return "label label-lg label-inline label-light-success";
        case null:
          return "label label-lg label-inline label-light-primary";
        default:
          return "label label-lg label-inline label-light-warning";
      }
    },
    statusLabel(status) {
      switch (status) {
        case 1:
          return "Editando";
        case 2:
          return "Enviado ao cliente";
        case 3:
          return "Autorizado";
        case 4:
          return "Cancelado";
        case 5:
          return "Finalizado";
        case 6:
          return "Não autorizado";
        case 7:
          return "Visualizado pelo cliente";
        case 8:
          return "Autorizado";
        case null:
          return "Editando";
        default:
          return "";
      }
    },
  },
  async mounted() {
    const { uuid } = this.$route.params;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.setLoading(true);
    this.$http
      .post("/ordemservico/busca-os", {
        uuid,
      })
      .then(({ data }) => {
        this.cliente = data?.cliente;
        this.emissor = data?.emissor;
        this.observacao = data?.observacao;
        this.pagamentos = data?.pagamentos;
        this.dataEntrada = data.dataEntrada ?? "Sem data";
        this.dataSaida = data.dataSaida ?? "Sem data";
        this.dataPrevisao = data.dataPrevisao ?? "Sem data";
        this.proximaRevisao = data.proximaRevisao ?? "Sem data";
        this.problema = data.problema;
        this.anexo1 = data?.anexo_1;
        this.anexo2 = data?.anexo_2;
        this.anexo3 = data?.anexo_3;
        this.anexo4 = data?.anexo_4;
        this.anexo5 = data?.anexo_5;
        this.anexo6 = data?.anexo_6;
        if (data?.produtos) this.produtos = data?.produtos;
        if (data?.servicos) this.servicos = data?.servicos;
        this.status = data?.status;
        this.valorTotal = data?.valorTotal;
        this.numero = data?.numero;
        this.desconto = data?.desconto;
        this.objeto = data?.objeto;
        this.identificadores = data?.identificadores;
        if (data?.contato) {
          this.contatoResposta = data?.contato;
        }
        if (data?.dataAlteracao) {
          this.dataResposta = data.dataAlteracao;
        }
        this.dataFetched = true;
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        this.$toast.error(`Erro ao buscar OS:\n${error}`);
      });
    await this.getInit();
  },
  created() {},
};
</script>
<style scoped>
svg {
  outline-width: 0;
  outline-color: transparent;
  color: rgb(241, 249, 255);
}

.container {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.select-city .vs__search::placeholder,
.select-city .vs__dropdown-toggle,
.select-city .vs__dropdown-menu {
  height: 10rem !important;
  /* width:  100%; */
  background-color: green !important;
}

.contato-input {
  height: 3rem;
}

.quebra-de-linha {
  white-space: pre-line;
}
</style>