var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('FilterFields',{attrs:{"fields":[
        {
          type: 'text',
          label: 'Descrição',
          field: 'descricao',
        },
        {
          type: 'select',
          label: 'Tipo',
          field: 'tipo',
          options: ['Receita', 'Despesa', 'Todos'],
        },
        {
          type: 'date',
          label: 'Data(s) de movimento(s)',
          field: 'data',
        },
      ]}}),_c('div',{staticClass:"d-flex align-items-center my-2 display: flex;"},[_c('b-dropdown',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.right",value:(''),expression:"''",modifiers:{"right":true}}],attrs:{"text":_vm.filtersStore.filterOptionSelected.label,"split":"","split-variant":"secondary","id":"menu","variant":"primary"}},[_c('b-dropdown-item',{attrs:{"active":_vm.filtersStore.filterOptionSelected.id === 1},on:{"click":() =>
              (_vm.filtersStore.filterOptionSelected = { id: 1, label: 'Hoje' })}},[_vm._v("Hoje")]),_c('b-dropdown-item',{attrs:{"active":_vm.filtersStore.filterOptionSelected.id === 2},on:{"click":() =>
              (_vm.filtersStore.filterOptionSelected = { id: 2, label: 'Ontem' })}},[_vm._v(" Ontem ")]),_c('b-dropdown-item',{attrs:{"active":_vm.filtersStore.filterOptionSelected.id === 3},on:{"click":() =>
              (_vm.filtersStore.filterOptionSelected = {
                id: 3,
                label: 'Essa semana',
              })}},[_vm._v(" Essa semana ")]),_c('b-dropdown-item',{attrs:{"active":_vm.filtersStore.filterOptionSelected.id === 4},on:{"click":() =>
              (_vm.filtersStore.filterOptionSelected = {
                id: 4,
                label: 'Semana passada',
              })}},[_vm._v(" Semana passada ")]),_c('b-dropdown-item',{attrs:{"active":_vm.filtersStore.filterOptionSelected.id === 5},on:{"click":() =>
              (_vm.filtersStore.filterOptionSelected = {
                id: 5,
                label: 'Esse mês',
              })}},[_vm._v(" Esse mês ")])],1),_c('b-col',[_c('Chart',{attrs:{"endpoint":"/financeiro/total","modulo":"financeiro"}})],1)],1)],1),_c('TableApp',{ref:"financeiro",attrs:{"titulo":"Movimento do Caixa","acaoBtn":"/#/financeiro/novo","textoBtn":"Novo Movimento","api-url":"/financeiro","fields":_vm.fields,"classFilterColumn":"descricao","perPage":10},scopedSlots:_vm._u([{key:"customRow",fn:function(props){return [(props.field == 'data')?_c('span',{attrs:{"slot":"data"},slot:"data"},[_c('div',{staticClass:"font-weight-bold text-muted"},[_vm._v(" "+_vm._s(_vm.formatDate(props.rowData.data))+" ")])]):_vm._e(),(props.field == 'descricao')?_c('span',{attrs:{"slot":"descricao"},slot:"descricao"},[_c('span',{staticClass:"pl-0 pt-8"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('a',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"},[_vm._v(_vm._s(props.rowData.descricao))]),_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v(_vm._s(props.rowData.plano_conta))])])])])]):_vm._e(),(props.field == 'valor')?_c('span',{attrs:{"slot":"valor"},slot:"valor"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v("R$ "+_vm._s(props.rowData.tipo == "E" ? _vm.numberToReal(props.rowData.valor, 2) : "-" + _vm.numberToReal(props.rowData.valor, 2))+" ")]),_c('span',{class:props.rowData.tipo == 'E'
              ? 'text-success font-weight-bold'
              : 'text-danger font-weight-bold'},[_vm._v(" "+_vm._s(props.rowData.tipo == "E" ? "Receita" : "Despesa")+" ")])]):_vm._e(),(props.field == 'meiopagamento')?_c('span',{attrs:{"slot":"meiopagamento"},slot:"meiopagamento"},[(props.rowData.meio_pagamento)?_c('div',{staticClass:"font-weight-bold text-muted"},[_vm._v(" "+_vm._s(props.rowData.meio_pagamento.descricao)+" ")]):_c('div',{staticClass:"font-weight-bold text-muted"},[_vm._v("-")])]):_vm._e(),(props.field == 'actions')?_c('span',{attrs:{"slot":"actions"},slot:"actions"},[_c('div',{staticClass:"row-actions",staticStyle:{"text-align":"right !important"}},[_c('span',{staticStyle:{"overflow":"visible","position":"relative","width":"130px"}},[_c('div',{staticClass:"dropdown dropdown-inline"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Editar'),expression:"'Editar'"}],staticClass:"btn btn-sm btn-info btn-hover-info btn-icon mr-2",attrs:{"to":{
                  name: 'financeiroEditar',
                  params: {
                    id: props.rowData.id,
                  },
                }}},[_c('Pencil',{attrs:{"size":16,"title":"Editar"}})],1),(props.rowData.id_empresa)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Excluir'),expression:"'Excluir'"}],staticClass:"btn btn-sm btn-danger btn-hover-danger btn-icon mr-2",on:{"click":function($event){return _vm.excluir(props.rowData)}}},[_c('TrashCanIcon',{attrs:{"size":16}})],1):_vm._e()],1)])])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }