<template>
  <div style="position: relative">
    <FilterFields :fields="[
      {
        type: 'text',
        label: 'Número',
        field: 'numero',
      },
      {
        type: 'text',
        label: 'Cliente',
        field: 'nomeCliente',
      },
      {
        type: 'date',
        label: 'Emissão',
        field: 'emissao',
      },
      {
        type: 'select',
        label: 'Status',
        field: 'status',
        options: [
          'Todos',
          'Autorizada',
          'Cancelada',
          'Editando',
          'Inutilizada',
          'Rejeitada',
        ],
      },
    ]" />
    <b-dropdown id="menu" variant="dark" v-b-tooltip.right="'Mais ações'" v-if="actionsStore.selectedRows.length > 0"
      style="position: absolute; top: 0; left: 140px">
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item v-b-modal.duplicarNFe :disabled="actionsStore.selectedRows.length > 1 ||
      actionsStore.selectedRows[0]?.status != 2 ||
      actionsStore.selectedRows[0]?.emitida_outro_software
      ">
        Duplicar NFe
      </b-dropdown-item>
      <b-dropdown-item @click="devolverNfe" :disabled="actionsStore.selectedRows.length > 1 ||
      actionsStore.selectedRows[0]?.status != 2
      ">
        Devolver NFe
      </b-dropdown-item>
      <b-dropdown-item v-b-modal.imprimirDuplicata :disabled="actionsStore.selectedRows.length > 1 ||
      actionsStore.selectedRows[0]?.status != 2 ||
      actionsStore.selectedRows[0]?.emitida_outro_software
      ">
        Imprimir Duplicata
      </b-dropdown-item>
      <b-dropdown-item v-b-modal.imprimirCarta :disabled="actionsStore.selectedRows.length > 1 ||
      actionsStore.selectedRows[0]?.status != 2 ||
      !actionsStore.selectedRows[0].cartaCorrecaoXml
      ">
        Imprimir CCe
      </b-dropdown-item>
      <b-dropdown-item v-b-modal.imprimirDanfeSimples :disabled="actionsStore.selectedRows.length > 1 ||
      actionsStore.selectedRows[0]?.status != 2 ||
      actionsStore.selectedRows[0]?.emitida_outro_software
      ">
        Imprimir Danfe Simples
      </b-dropdown-item>
      <b-dropdown-item v-b-modal.imprimirDanfeEtiqueta :disabled="actionsStore.selectedRows.length > 1 ||
      actionsStore.selectedRows[0]?.status != 2 ||
      actionsStore.selectedRows[0]?.emitida_outro_software
      ">
        Imprimir Etiqueta
      </b-dropdown-item>
    </b-dropdown>

    <b-modal id="duplicarNFe" title="Deseja duplicar a NFe selecionada?" centered size="xs">
      <label>NFe a ser duplicada
        <p>Número: {{ actionsStore.selectedRows[0]?.numero }}</p>
      </label>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button variant="primary" @click="duplicar" ref="doubleNFe" class="mr-5">
            Confirmar
          </b-button>
          <b-button @click="
                        {
      actionsStore.updateTable = !actionsStore.updateTable;
      actionsStore.selectedRows = [];
      cancel();
    }
      ">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="imprimirDuplicata" title="Deseja realizar a impressão da Duplicata da NFe selecionada?" centered
      size="xs">
      <label>
        <p>Número: {{ actionsStore.selectedRows[0]?.numero }}</p>
      </label>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button variant="primary" @click="imprimirDuplicata" ref="impressaoDuplicata" class="mr-5">
            Confirmar
          </b-button>
          <b-button @click="
                        {
      actionsStore.updateTable = !actionsStore.updateTable;
      actionsStore.selectedRows = [];
      cancel();
    }
      ">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="imprimirCarta" title="Deseja realizar a impressão da CCe da NFe selecionada?" centered size="xs">
      <label>
        <p>Número: {{ actionsStore.selectedRows[0]?.numero }}</p>
      </label>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button variant="primary" @click="imprimirCCe" ref="imprimirCartaCorrecao" class="mr-5">
            Confirmar
          </b-button>
          <b-button @click="
                        {
      actionsStore.updateTable = !actionsStore.updateTable;
      actionsStore.selectedRows = [];
      cancel();
    }
      ">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="imprimirDanfeSimples" title="Deseja realizar a impressão da Danfe simples?" centered size="xs">
      <label>
        <p>Número: {{ actionsStore.selectedRows[0]?.numero }}</p>
      </label>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button variant="primary" @click="imprimirSimples" ref="imprimirDanfeSimples" class="mr-5">
            Confirmar
          </b-button>
          <b-button @click="
                        {
      actionsStore.updateTable = !actionsStore.updateTable;
      actionsStore.selectedRows = [];
      cancel();
    }
      ">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="imprimirDanfeEtiqueta" title="Deseja realizar a impressão da etiqueta da Danfe?" centered size="xs">
      <label>
        <p>Número: {{ actionsStore.selectedRows[0]?.numero }}</p>
      </label>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button variant="primary" @click="imprimirEtiquetaDanfe" ref="imprimirDanfeEtiqueta" class="mr-5">
            Confirmar
          </b-button>
          <b-button @click="
                        {
      actionsStore.updateTable = !actionsStore.updateTable;
      actionsStore.selectedRows = [];
      cancel();
    }
      ">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <button style="position: absolute; left: 55px; top: -1px" @click="inutilizar()"
      class="btn btn-sm btn-warning btn-hover-danger btn-icon mr-2" v-b-tooltip="'Inutilizar numeração'">
      <CancelIcon :size="16" />
    </button>
    <button style="position: absolute; left: 100px; top: -1px" v-b-modal.modal-importar-xml
      class="btn btn-sm btn-warning btn-hover-danger btn-icon mr-2" v-b-tooltip="'Importar XML já autorizado'">
      <XmlIcon :size="16" />
    </button>
    <TableApp titulo="NFe" acaoBtn="/#/nfe-nova" textoBtn="Nova NFe" api-url="/nfe/listar-nfe" :fields="fields"
      classFilterColumn="numero" ref="nfeList" :perPage="10" :multiselect="true">
      <template slot="customRow" scope="props">
        <span slot="numero" class="pl-0 pt-8" v-if="props.field == 'numero'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.numero }}
              <br />
              <a v-if="props.rowData.chave" v-b-tooltip="'Clique para consultar a NFe'"
                @click="consultar(props.rowData)"
                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{
      props.rowData.chave.slice(1, 10) }}...</a>
              <p
              style="margin-bottom: 0rem; !important"
                v-if="props.rowData.confirmacao_abertura_email"
                v-b-tooltip.right="'E-mail visualizado'"
              >
                <Mail :size="15" />
              </p>
            </div>
          </div>
        </span>
        <span slot="nomeCliente" class="pl-0 pt-8" v-if="props.field == 'nomeCliente'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.nomeCliente }}
            </div>
          </div>
        </span>
        <span slot="emissao" class="pl-0 pt-8" v-if="props.field == 'emissao'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.emissao | moment("DD/MM/YYYY") }}
            </div>
          </div>
        </span>
        <span slot="status" v-if="props.field == 'status'" :class="statusClass(props.rowData.statusTransmissao)">
          {{ statusLabel(props.rowData.statusTransmissao) }}
        </span>
        <span slot="valorTotal" v-if="props.field == 'valorTotal'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg">
          R$
          {{ numberToReal(props.rowData.valorTotal, 2) }}
        </span>
        <span slot="actions" v-if="props.field == 'actions'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg">
          <div class="action-buttons">
            <button @click="imprimirPDF(props.rowData)" v-if="props.rowData.statusTransmissao === 4"
              v-b-tooltip="'Gerar Pdf'" class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
              <PdfIcon :size="22" />
            </button>
            <button @click="editarNfe(props.rowData)" v-if="props.rowData.statusTransmissao !== 4 &&
      props.rowData.statusTransmissao !== 14 &&
      props.rowData.statusTransmissao !== 3 &&
      props.rowData.statusTransmissao !== 15
      " class="btn btn-sm btn-info btn-hover-info btn-icon mr-2" v-b-tooltip="'Editar NFe'">
              <PencilIcon :size="20" />
            </button>
            <button v-b-modal.modal-correcao @click="openCorrecao(props.rowData)" v-b-tooltip="'Solicitar correçao'"
              v-if="props.rowData.statusTransmissao === 4 &&
      !props.rowData.emitida_outro_software
      " class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
              <Erase :size="20" />
            </button>
            <button @click="gerarlXml(props.rowData)" v-if="props.rowData.arquivoXml !== null &&
      props.rowData.statusTransmissao !== 15
      " v-b-tooltip="'Gerar XML'" class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
              <XmlIcon :size="22" />
            </button>
            <button @click="cancelNfe(props.rowData)" v-if="props.rowData.statusTransmissao === 4 &&
      !props.rowData.emitida_outro_software
      " v-b-tooltip="'Cancelar NFe'" class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2">
              <Close :size="20" />
            </button>
            <button @click="shareToWhatsapp(props.rowData)" v-if="props.rowData.statusTransmissao === 4"
              v-b-tooltip.righttop="'Encaminhar XML e PDF para o whatsapp do cliente'"
              class="btn btn-sm btn-success btn-hover-success btn-icon mr-2">
              <Whatsapp :size="20" />
            </button>
            <button @click="sendToEmail(props.rowData)" v-if="props.rowData.statusTransmissao === 4 &&
      !props.rowData.emitida_outro_software
      " v-b-tooltip="'Enviar nota por email'" class="btn btn-sm btn-info btn-hover-success btn-icon mr-2">
              <Email :size="20" v-if="!isLoading" />
            </button>
          </div>
        </span>
      </template>
    </TableApp>
    <b-modal id="modal-correcaoGerada" size="md" centered>
      <div class="mx-auto d-flex flex-column justify-content-center">
        <Success :size="100" class="text-success mx-auto" />
        <h1 class="text-center mt-5">Correção gerada com sucesso!</h1>
        <h5 class="text-center text-muted mt-5">
          Deseja visualizar p XML ou PDF gerados?
        </h5>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" ref="enviar_correcao" @click="openPdf">
          PDF
        </b-button>
        <b-button variant="primary" ref="enviar_correcao" @click="openXml">
          XML
        </b-button>
      </template>
    </b-modal>
    <b-modal id="modal-correcao" title="Carta de Correção" size="md" centered>
      <div class="mx-auto">
        <b-form class="form">
          <b-row>
            <b-col>
              <b-form-group label="Nota Fiscal" label-for="numero">
                <b-form-input disabled id="numero" name="numero" v-model="numero" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Cliente" label-for="nomeCliente">
                <b-form-input disabled id="nomeCliente" name="nomeCliente" v-model="nomeCliente" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <v-textarea full-width resize rows="5" auto-grow v-model="correcaoText"
                placeholder="Mínimo com 15 caracteres" />
            </b-col>
          </b-row>
        </b-form>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" ref="enviar_correcao" @click="enviarCorrecao">
          Enviar
        </b-button>
      </template>
    </b-modal>
    <b-modal id="selectEmailModal" title="Enviar NFe para email(s)" size="md" centered>
      <div class="mx-auto">
        <b-form-group label="Email" label-for="email" v-if="userEmailsToSend.length > 0">
          <VueSelect ref="emailToSend" :filterable="false" :options="userEmailsToSend" v-model="selectedEmail">
            <template slot="no-options"> Informe o E-mail </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.label }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.label }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>

        <b-form-group v-else id="selectedEmail" label-for="selectedEmail" label="Email">
          <b-form-input v-model="selectedEmail" />
        </b-form-group>

        <b-form-group id="ccEmail" label-for="ccEmail" label="Cópia para: (opcional)">
          <b-form-input v-model="ccEmail" autofocus />
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" @click="enviarEmail" ref="enviar-nfe-email">
          Enviar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-importar-xml" title="Importar NFe emitidas" size="lg" centered>
      <div class="mx-auto">
        <b-form class="form">
          <b-row>
            <b-col>
              <b-form-group label="XMLs" label-for="xmls">
                <b-form-file autofocus :file-name-formatter="formatXMLName" placeholder="Selecione os arquivos"
                  ref="xmlFiles" multiple accept=".xml" @change="setXMLFile">
                </b-form-file>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" ref="enviar_xmls" @click="importarXML">
          Importar
        </b-button>
      </template>
    </b-modal>

    <SendToWppModal :data="dataToWhatsapp" :openModal="wppOpenModal"
      @handleOpenModal="(openModal) => (wppOpenModal = openModal)" module="nfe" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CancelIcon from "vue-material-design-icons/FileCancel";
import TableApp from "@/components/Table/Table2";
import swal from "sweetalert";
import { SistemaMixin } from "@/modules/Application";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import Close from "vue-material-design-icons/FileCancel.vue";
import Email from "vue-material-design-icons/Email.vue";
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import PdfIcon from "vue-material-design-icons/FilePdfBox.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import XmlIcon from "vue-material-design-icons/Download.vue";
import Erase from "vue-material-design-icons/EraserVariant.vue";
import Success from "vue-material-design-icons/CheckCircleOutline.vue";
import "flatpickr/dist/themes/material_blue.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import VueSelect from "vue-select";
import FilterFields from "@/components/FilterFields";
import SendToWppModal from "@/components/SendToWppModal";
import Menu from "vue-material-design-icons/Menu";
import { actionsStore } from "@/components/Table/actionsStore";
import Mail from "vue-material-design-icons/Email";

export default {
  name: "nfe",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    CancelIcon,
    Close,
    PdfIcon,
    XmlIcon,
    PencilIcon,
    Erase,
    Success,
    Whatsapp,
    Email,
    VueSelect,
    FilterFields,
    SendToWppModal,
    Menu,
    Mail,
  },
  data() {
    return {
      actionsStore,
      components: {
        flatPickr,
      },
      wppNumberToSend: null,
      wppOpenModal: false,
      isLoading: false,
      userEmailsToSend: [],
      selectedEmail: null,
      nfeIdToSendEmail: null,
      ccEmail: null,
      dataToWhatsapp: null,
      fields: [
        {
          label: "Número da Nota",
          field: "numero",
          type: "text",
          slot: true,
          sortable: true,
          width: "15%",
        },
        {
          label: "Cliente",
          field: "nomeCliente",
          type: "text",
          slot: true,
          sortable: false,
          width: "35%",
        },
        {
          label: "Data de Emissão",
          field: "emissao",
          sortable: true,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
          width: "8%",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Total",
          field: "valorTotal",
          sortable: false,
          slot: true,
          width: "15%",
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
        },
      ],
      numeroNota: null,
      nomeCliente: null,
      correcaoText: null,
      pdfCorrecaoUrl: null,
      xmlCorrecaoUrl: null,
      nfeId: null,
      form: {},
      serie: null,
      xmlFiles: [],
    };
  },
  created() {
    this.FINALIZADA = 7;
    this.EDITANDO = 1;
    this.AUTORIZADA = 4;
    this.CANCELADA = 3;
    this.DENEGADA = 4;
    this.PROCESSAMENTO = 5;
    this.INUTILIZADA = 14;
  },
  methods: {
    async devolverNfe() {
      const id = this.actionsStore?.selectedRows[0]?.id;
      const numero = this.actionsStore?.selectedRows[0]?.numero;

      swal({
        title: "Devolver NFe?",
        text: `Deseja confirmar a devolução da NFe ${numero}, após confirmar verifique todos os dados e confirme se a natureza de operação esta de acordo com a devolução.`,
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/devolucao-venda", {
            id_nfe: id,
          })
          .then(({data}) => {
            this.$router.push({
              name: 'nfeNova',
              params: { nfeData: { ...data.dados, nfeId: data.id }, fromDevolucao: true },
            })
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    async duplicar() {
      const id = this.actionsStore?.selectedRows[0]?.id;

      this.addLoadingToButton("doubleNFe");
      if (!id) {
        this.$toast.error("NFe sem id válido!");
        this.removeLoadingToButton("doubleNFe");
        return;
      }
      try {
        await this.$http.post("/nfe/duplicar", {
          id,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("NFe duplicado com sucesso!");
        this.$refs.nfeList.refresh();
        this.$bvModal.hide("duplicarNFe");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("doubleNFe");
    },
    async imprimirDuplicata() {
      const id = this.actionsStore?.selectedRows[0]?.id;

      this.addLoadingToButton("impressaoDuplicata");
      if (!id) {
        this.$toast.error("NFe sem id válido!");
        this.removeLoadingToButton("impressaoDuplicata");
        return;
      }
      try {
        const result = await this.$http.post("/nfe/print-nfe", {
          id,
          impressao_duplicata: true,
        });
        if (result["data"]["duplicata"]) {
          window.open(result["data"]["duplicata"], "_blank");
        }
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Duplicata gerada com sucesso!");
        this.$bvModal.hide("imprimirDuplicata");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("impressaoDuplicata");
    },
    async imprimirCCe() {
      const id = this.actionsStore?.selectedRows[0]?.id;

      this.addLoadingToButton("imprimirCartaCorrecao");
      if (!id) {
        this.$toast.error("NFe sem id válido!");
        this.removeLoadingToButton("imprimirCartaCorrecao");
        return;
      }
      try {
        const result = await this.$http.post("/nfe/imprimir-correcao", {
          id_nfe: id,
        });
        if (result["data"]["dacce_url"]) {
          window.open(result["data"]["dacce_url"], "_blank");
        }
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("CCe impressa com sucesso!");
        this.$bvModal.hide("imprimirCarta");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("imprimirCartaCorrecao");
    },
    async imprimirSimples() {
      const id = this.actionsStore?.selectedRows[0]?.id;

      this.addLoadingToButton("imprimirDanfeSimples");
      if (!id) {
        this.$toast.error("NFe sem id válido!");
        this.removeLoadingToButton("imprimirDanfeSimples");
        return;
      }
      try {
        const result = await this.$http.post("/nfe/imprimir-simples", {
          id: id,
        });
        if (result["data"]["etiqueta-url"]) {
          window.open(result["data"]["etiqueta-url"], "_blank");
        }
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Danfe Simples impressa com sucesso!");
        this.$bvModal.hide("imprimirDanfeSimples");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("imprimirDanfeSimples");
    },
    async imprimirEtiquetaDanfe() {
      const id = this.actionsStore?.selectedRows[0]?.id;

      this.addLoadingToButton("imprimirDanfeEtiqueta");
      if (!id) {
        this.$toast.error("NFe sem id válido!");
        this.removeLoadingToButton("imprimirDanfeEtiqueta");
        return;
      }
      try {
        const result = await this.$http.post("/nfe/imprimir-etiqueta", {
          id: id,
        });
        if (result["data"]["etiqueta-url"]) {
          window.open(result["data"]["etiqueta-url"], "_blank");
        }
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Etiqueta impressa com sucesso!");
        this.$bvModal.hide("imprimirDanfeEtiqueta");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("imprimirDanfeEtiqueta");
    },
    imprimirNota(data) {
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/printNota", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["danfe-url"]) {
              window.open(result["data"]["danfe-url"], "_blank");
            }
            if (result["data"]["voucher-url"]) {
              window.open(result["data"]["voucher-url"]);
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Venda impressa com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    imprimirComprovanteConsignado(data) {
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/comprovante-consignado", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["venda-url"]) {
              window.open(result["data"]["venda-url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Comprovante impresso com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    async sendToEmail({ identificacaoCliente, id }) {
      this.isLoading = true;
      this.userEmailsToSend = [];
      try {
        swal({
          title: "Deseja enviar uma cópia da NFe por email?",
          text: "Aguarde, pois será redirecionado em breve",
          icon: "info",
          buttons: {
            cancel: {
              text: "Cancelar",
              value: "cancel",
              visible: true,
              closeModal: true,
            },
            confirm: {
              text: "OK",
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: false,
          closeModal: false,
          closeOnClickOutside: false,
        }).then(async (value) => {
          if (value === "cancel") {
            swal.close();
            swal.stopLoading();
            return;
          }
          try {
            const response = await this.$http.post("/pessoa/get-emails", {
              cnpj_cpf: identificacaoCliente,
            });
            swal.close();
            swal.stopLoading();
            if (response.data.emails.length > 0) {
              this.userEmailsToSend = response.data.emails.map(
                (item) => item.email
              );
            }
            this.nfeIdToSendEmail = id;
            this.$bvModal.show("selectEmailModal");
          } catch (error) {
            console.log(error);
            swal.close();
            swal.stopLoading();
          }
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async enviarEmail() {
      this.addLoadingToButton("enviar-nfe-email");
      try {
        await this.$http.post("/nfe/send-emails", {
          id: this.nfeIdToSendEmail,
          email: this.selectedEmail,
          email_cc: this.ccEmail,
        });
        this.$toast.success("Email enviado com sucesso");
      } catch (error) {
        console.log(error);
      }
      this.$bvModal.hide("selectEmailModal");
      this.ccEmail = null;
      this.nfeIdToSendEmail = null;
      this.selectedEmail = null;
      this.removeLoadingToButton("enviar-nfe-email");
    },
    async shareToWhatsapp(data) {
      this.dataToWhatsapp = data;
      this.wppOpenModal = true;
    },
    openCorrecao(nfeData) {
      this.numero = nfeData.numero;
      this.nomeCliente = nfeData.nomeCliente;
      this.nfeId = nfeData.id;
    },
    openPdf() {
      window.open(this.pdfCorrecaoUrl, "_blank");
    },
    openXml() {
      window.open(this.xmlCorrecaoUrl, "_blank");
    },
    async enviarCorrecao() {
      if (!this.correcaoText || this.correcaoText.length < 15) {
        this.$toast.error(
          "O texto de correção deve conter no mínimo 15 caracteres!"
        );
        return;
      } else {
        const submitButton = this.$refs["enviar_correcao"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");
        this.$http
          .post("/nfe/carta-correcao", {
            id: this.nfeId,
            correcao: this.correcaoText,
          })
          .then((response) => {
            const data = response.data;
            this.xmlCorrecaoUrl = data.cce_url;
            this.pdfCorrecaoUrl = data.dacce_url;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.removeAttribute("disabled", "");
            this.correcaoText = null;
            this.$bvModal.hide("modal-correcao");
            this.$bvModal.show("modal-correcaoGerada");
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      }
    },
    imprimirVenda(data) {
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/printVenda", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["venda-url"]) {
              window.open(result["data"]["venda-url"], "_blank");
            }

            if (result["data"]["voucher-url"]) {
              window.open(result["data"]["voucher-url"]);
            }

            swal.stopLoading();
            swal.close();
            this.$toast.success("Venda impressa com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarlXml(data) {
      swal({
        title: "Deseja realizar a visualização do xml?",
        text: "Aguarde, pois será gerado o xml automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/xml", {
            id: data.id,
          })
          .then((result) => {
            const resultData = result["data"]["xml-url"];
            if (resultData) {
              window.open(resultData, "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Xml gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    imprimirPDF(data) {
      swal({
        title: "Deseja gerar o pdf da NFe?",
        text: "Aguarde, pois será gerado o pdf automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/print-nfe", {
            id: data.id,
          })
          .then((result) => {
            const resultData = result["data"]["danfe-url"];
            if (resultData) {
              window.open(result["data"]["danfe-url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("PDF gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    transmitir(data) {
      swal({
        title: "Deseja realizar a transmissão da venda?",
        text: "Aguarde, pois será enviada a venda para transmissão!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/transmitter", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["nfe-url"]) {
              window.open(result["data"]["nfe-url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$refs.vendas.refresh();
            this.$toast.success("Nota fiscal gerada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    cancelNfe(data) {
      this.getRequestAdmin(() => {
        swal({
          title: "Deseja cancelar a NFe?",
          text: "Informe a justificativa do cancelamento!",
          icon: "info",
          content: "input",
          buttons: {
            cancel: {
              text: "Cancelar",
              value: "cancel",
              visible: true,
              closeModal: true,
            },
            confirm: {
              text: "OK",
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: false,
          closeModal: false,
          closeOnClickOutside: false,
        }).then(async (value) => {
          if (value === "cancel") {
            swal.close();
            swal.stopLoading();
            return;
          }

          if (value.length < 15) {
            swal.stopLoading();
            this.$toast.warning(
              "A justificativa tem que possuir no mínimo 15 caracteres"
            );
            return;
          }

          await this.$http
            .post("/nfe/cancel", {
              id: data.id,
              justificativa: value,
            })
            .then(() => {
              swal.stopLoading();
              swal.close();
              this.$toast.success("NFe cancelada com sucesso!");
              this.$refs.nfeList.refresh();
            })
            .catch(() => {
              swal.stopLoading();
              swal.close();
            });
        });
      })

    },
    async editarNfe(data) {
      swal({
        title: "Deseja editar a NFe?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        await this.$http
          .post("/nfe/nfe-by-id", {
            id: data.id,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            this.$toast.info("Você será redirecionado agora");
            this.$router.push({
              name: "nfeNova",
              params: { nfeData: { ...response.data.nfe, nfeId: data.id } },
            });
          })
          .catch(() => {
            swal.stopLoading();
            this.$toast.error("Erro ao solicitar o endpoint da NFe");
            swal.close();
          });
      });
    },
    inutilizar() {
      this.$swal
        .fire({
          title: "Deseja inutilizar numeração de notas?",
          text: "Informe a justificativa e a numeração desejada!",
          icon: "info",
          html: `
      <input type="text" id="from" class="swal2-input" placeholder="De">
      <input type="text" id="to" class="swal2-input" placeholder="Até">
      <input type="text" id="justificativa" class="swal2-input" placeholder="Justificativa">`,
          confirmButtonText: "Enviar",
          focusConfirm: false,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          preConfirm: async () => {
            const from = this.$swal.getPopup().querySelector("#from").value;
            const to = this.$swal.getPopup().querySelector("#to").value;
            const justificativa = this.$swal
              .getPopup()
              .querySelector("#justificativa").value;

            if (!justificativa || !to || !from) {
              this.$toast.warning("Preencha todos campos necessários");
              return;
            }
            if (justificativa < 15) {
              this.$toast.warning(
                "A justificativa tem que possuir no mínimo 15 caracteres"
              );
            }
            await this.$http
              .post("/nfe/inutilizar", {
                from: from,
                to: to,
                justificativa: justificativa,
                modelo: 55,
                serie: this.serie,
              })
              .then(() => {
                this.$toast.success("Notas inutilizadas com sucesso!");
                this.$refs.nfeList.refresh();
              })
              .catch(() => { });
          },
        })
        .then(() => { });
    },
    async setXMLFile(event) {
      this.xmlFiles = await event.target.files;
    },
    formatXMLName(files) {
      return files.length === 1 ? files[0].name : `${files.length} arquivos selecionados`
    },
    async importarXML() {
      if (!this.xmlFiles) {
        return this.$toast.warning("Nenhum arquivo xml selecionado!");
      }
      if (this.xmlFiles.length > 20) {
        return this.$toast.warning("Importe 20 arquivos xml por vez!");
      }
      const submitButton = this.$refs["enviar_xmls"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.setAttribute("disabled", "");
      const formData = new FormData();

      for (let i = 0; i < this.xmlFiles.length; i++) {
        formData.append("xmls[" + i + "]", this.xmlFiles[i]);
      }

      this.$http
        .post("/nfe/importar-nfe-software", formData)
        .then(() => {
          this.$toast.success("NFe importadas com sucesso!");
          this.$bvModal.hide("modal-importar-xml");
          this.$refs.nfeList.refresh();
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
          submitButton.removeAttribute("disabled", "");
        })
        .catch(() => {
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
          submitButton.removeAttribute("disabled", "");
          this.$bvModal.hide("modal-importar-xml");
        });
    },
    consultar(data) {
      swal({
        title: "Deseja consultar a venda?",
        text: "Aguarde pois será consultado a venda nos servidores!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/consult", {
            id: data.id,
          })
          .then((result) => {
            swal.stopLoading();
            swal.close();
            this.$toast.success(result.data.msg);
            this.$copyText(data.chave);
            this.$refs.nfeList.refresh();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },

    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir a NFe?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/nfe/delete-nfe", {
              id: data.id,
            });
            this.$refs.nfeList.refresh();
            this.$toast.success("NFe excluída!");
          }
        });
    },
  },
  consultar(data) {
    swal({
      title: "Deseja consultar a NFe?",
      text: "Aguarde pois será consultado a venda nos servidores!",
      icon: "info",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: "cancel",
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: "OK",
          visible: true,
          closeModal: false,
        },
      },
      dangerMode: false,
      closeModal: false,
      closeOnClickOutside: false,
    }).then(async (value) => {
      if (value === "cancel") {
        swal.close();
        swal.stopLoading();
        return;
      }
      await this.$http
        .post("/nfe/consult", {
          id: data.id,
        })
        .then((result) => {
          swal.stopLoading();
          swal.close();
          this.$toast.success(result.data.msg);
          this.$refs.nfeList.refresh();
        })
        .catch(() => {
          swal.stopLoading();
          swal.close();
        });
    });
  },
  dateRangeFilter(data, filterString) {
    let dateRange = filterString.split("to");
    let startDate = Date.parse(dateRange[0]);
    let endDate = Date.parse(dateRange[1]);
    return (data =
      Date.parse(data) >= startDate && Date.parse(data) <= endDate);
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    const { data } = await this.$http.post("/configuracoes/current-config", {
      modelo: 55,
    });
    if (data.length > 0) {
      this.serie = data[0].serie;
    }
    let inputs = ['input[placeholder="Data"]'];
    inputs.forEach(function (input) {
      flatPickr(input, {
        dateFormat: "Y-m-d",
        allowInput: true,
        locale: Portuguese,
      });
    });
  },
};
</script>

<style>
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #ee7361 !important;
}

.headerColumn {
  text-align: start;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #ee7361 !important;
  color: #ee7361 !important;
}
</style>
