import Emitente from "@/modules/Emitente/pages/Index";

export default [
  {
    name: "emitente",
    path: "/emitente",
    component: Emitente,
    meta: { title: "Emitente - Nuts" },
  },
];
