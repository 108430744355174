import boletosConfig from "@/modules/Boletos/pages/Index";
import questConfig from "@/modules/Boletos/pages/Quest";

export default [
  {
    name: "boletosConfig",
    path: "/boletosConfig",
    component: boletosConfig,
    meta: { title: "Boleto Config - Nuts" },
  },
  {
    name: "boletosConfigQuest",
    path: "/quest-config",
    component: questConfig,
    meta: { title: "Boleto Config - Nuts" },
  },
];
