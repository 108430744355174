import op from "@/modules/Producao/pages/Index";
import opNova from "@/modules/Producao/pages/Create";

export default [
  {
    path: "/producao",
    name: "producao",
    component: op,
    meta: { title: "Produção - Nuts" },
  },
  {
    path: "/producao-nova",
    name: "producaoNova",
    component: opNova,
    meta: { title: "Produção - Nuts" },
  },
];
