import tributacoesLista from "@/modules/Tributacoes/pages/Index";
import tributacoesNova from "@/modules/Tributacoes/pages/Create";
import tributacoesEditar from "@/modules/Tributacoes/pages/Update";

export default [
  {
    name: "tributacoesLista",
    path: "/tributacoes",
    component: tributacoesLista,
    meta: { title: "Tributações - Nuts" },
  },
  {
    name: "tributacoesNova",
    path: "/tributacoes/nova",
    component: tributacoesNova,
    meta: { title: "Tributações - Nuts" },
  },
  {
    name: "tributacoesEditar",
    path: "/tributacoes/:id/editar",
    component: tributacoesEditar,
    meta: { title: "Tributações - Nuts" },
  },
];
