import assinatura from "@/modules/Assinatura/pages/Assinatura";

export default [
  {
    path: "/assinatura",
    name: "assinatura",
    component: assinatura,
    meta: { title: "Assinatura - Nuts" },
  },
];
