import os from "@/modules/OS/pages/Index";
import osNova from "@/modules/OS/pages/Create";

export default [
  {
    name: "ordensServicos",
    path: "/ordensServicos",
    component: os,
    meta: { title: "OS - Nuts" },
  },
  {
    path: "/ordensServicos-nova",
    name: "ordensServicosNova",
    component: osNova,
    meta: { title: "OS - Nuts" },
  },
];
