import gruposLista from "@/modules/Grupos/pages/Index";
import gruposNovo from "@/modules/Grupos/pages/Create";
import gruposEditar from "@/modules/Grupos/pages/Update";

export default [
  {
    name: "gruposLista",
    path: "/grupos",
    component: gruposLista,
    meta: { title: "Grupos - Nuts" },
  },
  {
    name: "gruposNovo",
    path: "/grupos/novo",
    component: gruposNovo,
    meta: { title: "Grupos - Nuts" },
  },
  {
    name: "gruposEditar",
    path: "/grupos/:id/editar",
    component: gruposEditar,
    meta: { title: "Grupos - Nuts" },
  },
];
