import contadorLista from "@/modules/Contador/pages/Index";
import contadorNovo from "@/modules/Contador/pages/Create";
import contadorEditar from "@/modules/Contador/pages/Update";

export default [
  {
    name: "contadorLista",
    path: "/contador",
    component: contadorLista,
    meta: { title: "Contador - Nuts" },
  },
  {
    name: "contadorNovo",
    path: "/contador/novo",
    component: contadorNovo,
    meta: { title: "Contador - Nuts" },
  },
  {
    name: "contadorEditar",
    path: "/contador/:id_contador/editar",
    component: contadorEditar,
    meta: { title: "Contador - Nuts" },
  },
];
