export default class CobrancaData {
  total_assinaturas = 0;

  total_reais_assinaturas = 0;

  constructor({ total_assinaturas = 0, total_reais_assinaturas = 0 }) {
    this.total_assinaturas = total_assinaturas;
    this.total_reais_assinaturas = total_reais_assinaturas;
  }
}
