<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="card-form">
          <div class="card-list">
            <div class="card-form-assinatura__inner">
              <b-col md="12" v-if="dia_pagamento_revenda">
                <h2>Pagamento das assinaturas</h2>
                <h4>
                  Definia o dia para realizarmos a cobrança das suas
                  assinaturas, no valor de R$
                  {{ numberToReal(totais.total_reais_assinaturas, 2) }}. Esse valor
                  é referente a um total de {{ totais.total_assinaturas }}
                  assinatura(s).
                </h4>
                <h4>
                  Deseja visualizar sua fatura?
                  <a
                    href="javascript:"
                    @click="baixarFatura"
                    class="remove-pgto"
                  >
                    Visualizar
                  </a>
                </h4>
                <br />
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Dia da Cobrança"
                  label-for="dia_pagamento_revenda"
                >
                  <select
                    name="dia_pagamento_revenda"
                    id="dia_pagamento_revenda"
                    v-model="form"
                    class="form-control"
                  >
                    <option
                      v-for="(d, index) in diasCobranca"
                      :value="d.dia"
                      :key="'d' + index"
                    >
                      {{ d.dia }}
                    </option>
                  </select>
                </b-form-group>
              </b-col>
              <b-col class="col-lg-12" style="text-align: center">
                <button
                  @click="cadastrarDia"
                  class="btn btn-primary font-weight-bold"
                >
                  Atualizar
                </button>
                <router-link
                  class="btn btn-clean font-weight-bold"
                  :to="{
                    name: 'clientesLista',
                  }"
                >
                  Voltar
                </router-link>
              </b-col>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import CobrancaData from "./CobrancaData";
import swal from "sweetalert";

export default {
  name: "app-cobranca-revenda",
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      dia_pagamento_revenda: null,
      form: null,
      totais: [],
      diasCobranca: [
        {
          dia: 5,
        },
        {
          dia: 10,
        },
      ],
    };
  },
  directives: {
    mask,
  },
  computed: {
    ...mapGetters(["itsblocked", "idRevenda", "dadosRevenda", "isRevenda"]),
  },
  async mounted() {
    if (!this.isRevenda) {
      this.$router.push({ name: "dashboard" });
    }
    await this.getDataInit();
  },

  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.setLoading(true);
      const data = await this.getConfigAssinaturasRevenda();

      if (!data.assinaturas) {
        this.setLoading(false);
        this.$toast.error("Assinatruras não encontradas!");
        return;
      }

      this.totais = new CobrancaData(data.totais);

      if (data.dia_pagamento_revenda) {
        this.dia_pagamento_revenda =
          data.dia_pagamento_revenda.dia_pagamento_revenda ?? null;
        this.form = data.dia_pagamento_revenda.dia_pagamento_revenda ?? null;
      }

      this.setLoading(false);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async cadastrarDia() {
      this.setLoading(true);
      await this.$http
        .post("/empresa/revenda-pagamento", {
          dia_pagamento_revenda: this.form,
        })
        .then((result) => {
          if (result) {
            this.setLoading(false);
            this.$toast.success("Fatura atualizada com sucesso!");
            this.getDataInit();
          }
        });
      this.setLoading(false);
    },
    baixarFatura() {
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/empresa/baixar-fatura-revenda")
          .then((result) => {
            if (result["data"]["fatura-url"]) {
              window.open(result["data"]["fatura-url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Fatura impressa com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
  },
};
</script>

<style lang="scss">
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
@import "../../../assets/styles/styleCardPayment.scss";
</style>
