import bancosLista from "@/modules/Bancos/pages/Index";
import bancoNovo from "@/modules/Bancos/pages/Create";
import bancoEditar from "@/modules/Bancos/pages/Update";
import bancoDigital from "@/modules/Bancos/pages/Digital";
import bancosMovimentacao from "@/modules/Bancos/pages/Movimentacao";

export default [
  {
    name: "bancosLista",
    path: "/bancos",
    component: bancosLista,
    meta: { title: "Bancos - Nuts" },
  },
  {
    name: "bancosNovo",
    path: "/bancos/novo",
    component: bancoNovo,
    meta: { title: "Bancos - Nuts" },
  },
  {
    name: "bancosEditar",
    path: "/bancos/:id/editar",
    component: bancoEditar,
    meta: { title: "Bancos - Nuts" },
  },
  {
    name: "bancoDigital",
    path: "/bancoDigital",
    component: bancoDigital,
    meta: { title: "Digital - Nuts" },
  },
  {
    name: "bancosMovimentacao",
    path: "/bancos/:id_banco/movimentacao",
    component: bancosMovimentacao,
    meta: { title: "Movimentação Bancária - Nuts" },
  },
];
