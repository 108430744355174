import nfe from "@/modules/NFe/pages/Index";
import nfeNova from "@/modules/NFe/pages/Create";

export default [
  {
    name: "nfe",
    path: "/nfe",
    component: nfe,
    meta: { title: "NFe - Nuts" },
  },
  {
    path: "/nfe-nova",
    name: "nfeNova",
    component: nfeNova,
    meta: { title: "NFe - Nuts" },
  },
];
