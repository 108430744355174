import orcamento from "@/modules/Orcamento/pages/Index";
import orcamentoNovo from "@/modules/Orcamento/pages/Create";

export default [
  {
    name: "orcamento",
    path: "/orcamento",
    component: orcamento,
    meta: { title: "Orçamento - Nuts" },
  },
  {
    name: "orcamentoNovo",
    path: "/orcamento-novo",
    component: orcamentoNovo,
    meta: { title: "Orçamento - Nuts" },
  },
];
