import prospectLista from "@/modules/Prospect/pages/Index";

export default [
  {
    name: "prospectLista",
    path: "/prospect",
    component: prospectLista,
    meta: { title: "Prospect - Nuts" },
  },
];
