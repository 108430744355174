import dashboard from "@/modules/Dashboard/pages/Dashboard";

export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: { title: "Dashboard - Nuts" },
  },
];
