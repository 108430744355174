import unLista from "@/modules/UnidadeMedidas/pages/Index";
import unNova from "@/modules/UnidadeMedidas/pages/Create";
import unEditar from "@/modules/UnidadeMedidas/pages/Update";

export default [
  {
    name: "unidades",
    path: "/unidades",
    component: unLista,
    meta: { title: "Unidades - Nuts" },
  },
  {
    name: "unidadesNova",
    path: "/unidades/nova",
    component: unNova,
    meta: { title: "Unidades - Nuts" },
  },
  {
    name: "unidadesEditar",
    path: "/unidades/:id/editar",
    component: unEditar,
    meta: { title: "Unidades - Nuts" },
  }
];
