<template>
  <TableApp
    titulo="Movimentação bancária"
    api-url="bancos/movimentacoes"
    :fields="fields"
    classFilterColumn="bancosMovimentacao"
    ref="bancosMovimentacao"
    :perPage="10"
    :id_banco="$route.params.id_banco"
    acaoBtn="/#/bancos"
    textoBtn="Voltar"
  >
    <template slot="customRow" slot-scope="props">
      <span slot="tipo" v-if="props.field == 'tipo'">
        <div class="font-weight-bold" v-if="props.rowData.tipo == 1">
          <span class="label label-lg label-light-success label-inline"
            >Entrada</span
          >
        </div>
        <div class="font-weight-bold" v-else>
          <span class="label label-lg label-light-danger label-inline"
            >Saída</span
          >
        </div>
      </span>
      <span slot="valor" v-if="props.field == 'valor'">
        <div class="font-weight-bold">
          R$ {{ numberToReal(props.rowData.valor, 2) }}
        </div>
      </span>
      <span slot="data_movimento" v-if="props.field == 'data_movimento'">
        <div class="font-weight-bold text-muted">
          {{ formatDate(props.rowData.data_movimento) }}
        </div>
      </span>
      <span slot="descricao" v-if="props.field == 'descricao'">
        <div class="font-weight-bold text-muted">
          {{ props.rowData.descricao }}
        </div>
      </span>
    </template>
  </TableApp>
</template>

<script>
import { mapGetters } from "vuex";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-movimentacao-banco",
  mixins: [SistemaMixin],
  components: {
    TableApp,
  },
  data() {
    return {
      fields: [
        {
          label: "Tipo",
          field: "tipo",
          type: "text",
          slot: true,
          sortable: true,
          width: "5%",
        },
        {
          label: "Valor",
          field: "valor",
          type: "text",
          slot: true,
          sortable: true,
          width: "10%",
        },
        {
          label: "Data Movimento",
          field: "data_movimento",
          type: "date",
          slot: false,
          sortable: true,
          width: "15%",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {
          label: "Descrição",
          field: "descricao",
          type: "text",
          slot: false,
          sortable: false,
          width: "25%",
        },
      ],
      banco: null,
      titulo: null,
    };
  },
  methods: {
    async getBanco(id) {
      try {
        const { data } = await this.$http.post("/bancos/find", {
          id_banco: id,
        });

        this.banco = data;
        this.titulo =
          "Movimentação bancária: " +
          data.nome +
          " " +
          data.agencia +
          "/" +
          data.conta;
      } catch (error) {
        return null;
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    await this.getBanco(this.$route.params.id_banco);
  },
};
</script>
