import veiculos from "@/modules/Logistica/pages/Veiculos";
import veiculosNovo from "@/modules/Logistica/pages/VeiculosForm";
import motoristas from "@/modules/Logistica/pages/Motoristas";
import motoristasNovo from "@/modules/Logistica/pages/MotoristasForm";
import mdfe from "@/modules/Logistica/pages/MDFe";
import mdfeForm from "@/modules/Logistica/pages/MDFeForm";
// import nfeNova from "@/modules/NFe/pages/Create";

export default [
  {
    name: "veiculos",
    path: "/veiculos",
    component: veiculos,
    meta: { title: "Veiculos - Nuts" },
  },
  {
    name: "novoVeiculos",
    path: "/veiculos/novo-veiculo",
    component: veiculosNovo,
    meta: { title: "Veiculos - Nuts" },
  },
  {
    name: "motoristas",
    path: "/motoristas",
    component: motoristas,
    meta: { title: "Motoristas - Nuts" },
  },
  {
    name: "novoMotoristas",
    path: "/motoristas/novo-motorista",
    component: motoristasNovo,
    meta: { title: "Motoristas - Nuts" },
  },
  {
    name: "mdfe",
    path: "/mdfe",
    component: mdfe,
    meta: { title: "MDFe - Nuts" },
  },
  {
    name: "novaMdfe",
    path: "/mdfe/nova-mdfe",
    component: mdfeForm,
    meta: { title: "MDFe - Nuts" },
  },
  // {
  //   path: "/nfe-nova",
  //   name: "nfeNova",
  //   component: nfeNova,
  //   meta: { title: "NFe - Nuts" },
  // },
];
