import { reactive } from 'vue';

export const store = reactive({
  formGerais: {},
  formObjeto: {},
  formProblema: {},
  formProduto: {},
  formServicos: {},
  formPagamentos: {},
  formObservacoes: {},
  formAnexos: {},
  isUpdate: false,
  currentTab: 0,
  dataFetching: {
    produtos: true,
    servicos: true,
  },
});
