import configuracoesLista from "@/modules/Configuracoes/pages/Index";
import configuracoesNovo from "@/modules/Configuracoes/pages/Create";
import configuracoesEditar from "@/modules/Configuracoes/pages/Update";

export default [
  {
    name: "configuracoesLista",
    path: "/configuracoes",
    component: configuracoesLista,
    meta: { title: "Ambiente - Nuts" },
  },
  {
    name: "configuracoesNovo",
    path: "/configuracoes/novo",
    component: configuracoesNovo,
    meta: { title: "Ambiente - Nuts" },
  },
  {
    name: "configuracoesEditar",
    path: "/configuracoes/:id/editar",
    component: configuracoesEditar,
    meta: { title: "Ambiente - Nuts" },
  }
];
