import { reactive } from 'vue';

export const store = reactive({
  formCabecalho: {},
  formTomador: {},
  formServicos: [],
  formPagamento: {},
  os: [],
  currentTab: 0,
  dataFetched: false,
  informacoesComplementares: null,
});
