<template>
  <b-tab
    title="Cliente"
    :active="isTheCurrentTab"
    @click="() => (store.currentTab = 0)"
    :disabled="!isTheCurrentTab && !store.isUpdate"
  >
    <b-row>
      <b-col md="5">
        <b-form-group label="Cliente" label-for="nome">
          <VueSelect
            ref="buscarCliente"
            :filterable="false"
            :state="validateClienteState('nome')"
            @input="setCliente"
            :class="{ hasErrors: clienteError, inputFilled: clienteFilled }"
            :options="clientes"
            @search="onSearchCliente"
            v-model="form"
          >
            <template slot="no-options"> Informe o nome do cliente </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>{{ option.codigo }} - {{ option.nome }}</div>
                <span class="text-muted"
                  >CPF: {{ formatCpfCnpj(option.cnpj_cpf) }}</span
                >
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="CPF/CNPJ" label-for="cnpj_cpf">
          <b-form-input
            :key="keyToReRender"
            disabled
            id="cnpj_cpf"
            name="cnpj_cpf"
            v-mask="['###.###.###-##', '###.###.###/####-##']"
            v-model="form.cnpj_cpf"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="10" v-if="form?.enderecos">
        <b-form-group
          :key="keyToReRender"
          label="Endereço"
          label-for="endereco"
        >
          <b-form-input
            disabled
            id="endereco"
            name="endereco"
            :value="formatEndereco(form?.enderecos[0])"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="5">
        <b-form-group label="Solicitante" label-for="solicitante">
          <b-form-input
            id="solicitante"
            name="solicitante"
            v-model="solicitante"
            @input="setSolicitante"
            maxlength="50"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="5">
        <b-form-group label="Vendedor" label-for="vendedor">
          <VueSelect
            ref="buscaVendedor"
            :filterable="false"
            @input="setVendedor"
            :options="vendedores"
            v-model="vendedor"
            @search="onSearchVendedor"
          >
            <template slot="no-options"> Informe o nome do vendedor </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.nome }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <button
          ref="add_buyer"
          class="btn btn-primary font-weight-bold"
          v-b-modal.newClient
          style="font-size: 11px; margin-top: 30px"
        >
          Novo Cliente
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4" style="margin-top: 20px" v-if="form.tipo_atacado">
        <span class="label label-lg label-inline label-light-warning"
          >Tipo Atacado</span
        >
      </b-col>
    </b-row>

    <b-modal id="newClient" size="lg" centered title="Informe os dados">
      <b-row> </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="Nome" label-for="nome">
            <b-form-input
              id="nome"
              name="nome"
              v-model="nomeCliente"
              maxlength="50"
              required
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="CPF/CNPJ" label-for="cnpj_cpf">
            <b-form-input
              id="cpfCnpjCliente"
              name="cpfCnpjCliente"
              v-mask="['###.###.###-##', '###.###.###/####-##']"
              v-model="cpfCnpjCliente"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="CEP" label-for="formEndereco.cep">
            <b-form-input
              ref="cep"
              id="formEndereco.cep"
              name="formEndereco.cep"
              v-model="formEndereco.cep"
              v-mask="['#####-###']"
              aria-describedby="formEndereco.cep"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group style="margin-top: 30px">
            <a class="btn btn-primary font-weight-bold" @click="consultaCep">
              Consultar CEP
            </a>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Número" label-for="formEndereco.numero">
            <b-form-input
              id="formEndereco.numero"
              name="formEndereco.numero"
              v-model="formEndereco.numero"
              aria-describedby="formEndereco.numero"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Bairro" label-for="formEndereco.bairro">
            <b-form-input
              id="formEndereco.bairro"
              name="formEndereco.bairro"
              v-model="formEndereco.bairro"
              aria-describedby="formEndereco.bairro"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="5">
          <b-form-group label="Logradouro" label-for="formEndereco.logradouro">
            <b-form-input
              id="formEndereco.logradouro"
              name="formEndereco.logradouro"
              v-model="formEndereco.logradouro"
              aria-describedby="formEndereco.logradouro"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Complemento"
            label-for="formEndereco.complemento"
          >
            <b-form-input
              id="formEndereco.complemento"
              name="formEndereco.complemento"
              v-model="formEndereco.complemento"
              aria-describedby="formEndereco.complemento"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Cidade" label-for="formEndereco.cidade">
            <VueSelect
              label="cidade"
              @input="setCidade"
              :options="cidades"
              @search="onSearch"
            >
              <template slot="no-options"> Informe o nome da cidade </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.cidade + " - " + option.uf }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.cidade + " - " + option.uf }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
        <b-col md="1">
          <button
            type="button"
            style="margin-top: 25px; margin-right: 15px"
            @click="onSubmitEndereco"
            class="btn btn-light-primary btn-bold"
          >
            Adicionar
          </button>
        </b-col>
        <div class="table-responsive">
          <b-col md="12">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>CEP</th>
                  <th>Logradouro</th>
                  <th>Bairro</th>
                  <th>Número</th>
                  <th>Complemento</th>
                  <th>Cidade</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, index) in enderecos" :key="'endereco_' + index">
                  <td>{{ e.cep }}</td>
                  <td>{{ e.logradouro }}</td>
                  <td>{{ e.bairro }}</td>
                  <td>{{ e.numero }}</td>
                  <td>{{ e.complemento }}</td>
                  <td>
                    {{
                      e.cidade == null
                        ? ""
                        : e.cidade.cidade + " - " + e.cidade.uf
                    }}
                  </td>
                  <td align="right">
                    <button
                      @click="
                        () => {
                          $toast.error('Endereço excluído');
                          enderecos.splice(index, 1);
                        }
                      "
                      class="btn btn-light-primary btn-sm btn-bold"
                    >
                      <Close :size="16" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </div>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          @click="
            () => {
              cancel();
              openModal = false;
            }
          "
        >
          Cancelar
        </b-button>
        <b-button variant="primary" ref="saveClient" @click="saveClient">
          Confirmar
        </b-button>
      </template>
    </b-modal>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storePedidos";
import loadsh from "loadsh";
import Close from "vue-material-design-icons/Close.vue";
import ClienteData, { ClienteDataRequired } from "../ClienteData";

export default {
  name: "app-pedido-form-clienteTab",
  components: {
    VueSelect,
    Close,
  },
  directives: {
    mask,
  },
  data() {
    return {
      form: {
        nome: null,
        cnpj_cpf: null,
      },
      clientes: [],
      cliente: null,
      vendedor: null,
      solicitante: null,
      keyToReRender: 0,
      store,
      vendedores: [],
      clienteError: false,
      enderecoError: false,
      clienteFilled: false,
      nomeCliente: null,
      cpfCnpjCliente: null,
      enderecos: [],
      cidades: [],
      formEndereco: {},
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  validations: {
    form: ClienteDataRequired,
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleLoading(value) {
      this.setLoading(value);
    },
    onSearchCliente(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchCliente(loading, search, this);
      }
    },
    validateClienteState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setVendedor(vendedor) {
      this.store.formCliente.vendedor = vendedor.id_vendedor;
    },
    searchCliente: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getClientes(search);
      loading(false);
    }, 350),
    onSearchVendedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchVendedor(loading, search, this);
      }
    },
    searchVendedor: _.debounce(async (loading, search, vm) => {
      vm.vendedores = await vm.getVendedorByNome(search);
      if (loading) loading(false);
    }, 350),
    setCliente(cliente) {
      if (!cliente) return;
      this.form = cliente;
      this.clienteFilled = true;
      if (Object.keys(this.$route.params).length !== 0) {
        this.solicitante = this.$route.params.pedidoData?.solicitante ?? "";
      }
    },
    setSolicitante(solicitante) {
      this.store.formCliente.solicitante = solicitante;
    },
    formatEndereco(endereco) {
      if (!endereco) return "";
      const formatData = `${endereco.cep ?? ""}, ${
        endereco.logradouro ?? ""
      }, ${endereco.numero ?? ""}, ${endereco.bairro ?? ""} - ${
        endereco?.cidade?.cidade ?? ""
      } / ${endereco?.cidade?.uf ?? ""}`;
      return formatData;
    },
    async saveClient() {
      this.addLoadingToButton("saveClient");

      if (!this.nomeCliente) {
        this.$toast.warning("Informe o nome do cliente");
        this.removeLoadingToButton("saveClient");
        return;
      }
      try {
        var newObjetoData = {
          nome: this.nomeCliente,
          cnpj_cpf: this.cpfCnpjCliente ?? null,
          pessoa_tipo: [
            {
              id_tipo: 1,
              descricao: "Cliente",
            },
          ],
        };

        if (this.enderecos && this.formEndereco.cep) {
          if (!this.cidade) {
            this.$toast.warning("Selecione a cidade");
            this.removeLoadingToButton("saveClient");
            return;
          }

          newObjetoData.endereco = this.enderecos;
        }

        if (this.enderecos.length == 0 && this.cidade) {
          this.enderecos.push({
            numero: this.formEndereco.numero,
            cep: this.formEndereco.cep,
            logradouro: this.formEndereco.logradouro,
            bairro: this.formEndereco.bairro,
            complemento: this.formEndereco.complemento,
            id_endereco_tipo: 1,
            id_cidade: this.cidade.id_cidade,
            cidade: this.cidade,
            tipo: {
              id_tipo: 1,
              descricao: "Principal",
            },
            id_tipo: 1,
          });
        }

        const response = await this.$http.post("/pessoa/create", newObjetoData);
        if (response.data) {
          this.setCliente(response.data);
        }
        this.cidade = {};
        this.formEndereco = {};
        this.nomeCliente = null;
        this.cpfCnpjCliente = null;
        this.enderecos = [];
        this.$bvModal.hide("newClient");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("saveClient");
    },
    async onSubmitEndereco() {
      if (!this.cidade.id_cidade) {
        return this.$toast.error("Selecione uma cidade!");
      }

      this.enderecos.push({
        numero: this.formEndereco.numero,
        cep: this.formEndereco.cep,
        logradouro: this.formEndereco.logradouro,
        bairro: this.formEndereco.bairro,
        complemento: this.formEndereco.complemento,
        id_endereco_tipo: 1,
        id_cidade: this.cidade.id_cidade,
        cidade: this.cidade,
        tipo: {
          id_tipo: 1,
          descricao: "Principal",
        },
        id_tipo: 1,
      });

      this.$toast.success("Endereço adicionado!");
      return;
    },
    async consultaCep() {
      this.setLoading(true);
      const cep = await this.getCep(this.formEndereco.cep);

      if (cep.length == 0) {
        this.setLoading(false);
        return;
      }

      this.formEndereco.logradouro = cep.logradouro;
      this.formEndereco.bairro = cep.bairro;

      if (cep.erro) {
        this.setLoading(false);
        this.$toast.warning("Confira o CEP informado!");
        return;
      }

      const city = await this.getCidadeByUF(cep.localidade, cep.uf);

      this.cidades = city;
      this.setLoading(false);
    },
    setCidade(cidade) {
      this.cidade = cidade;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: loadsh.debounce(async (loading, search, vm) => {
      const city = await vm.getCidade(search);
      vm.cidades = city;
      loading(false);
    }, 350),
  },
  async created() {
    this.tipoEndereco = await this.getTipoEndereco();
    if (Object.keys(this.$route.params).length !== 0) {
      this.handleLoading(true);
      const id_pessoa = this.$route.params.pedidoData.cliente.id_pessoa;
      this.vendedor = this.$route.params.pedidoData?.vendedor;
      this.solicitante = this.$route.params.pedidoData?.solicitante;
      this.$http
        .post("/pessoa/find", {
          id_pessoa,
        })
        .then((response) => {
          this.setCliente(response.data);
        })
        .catch((error) =>
          this.$toast.error(`Erro ao buscar cliente:\n${error}`)
        );
      this.handleLoading(false);
    } else {
      this.vendedor = null;
      this.solicitante = null;
      this.store.formCliente.solicitante = null;
      this.store.formCliente.vendedor = null;
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 0;
    },
  },
  watch: {
    "store.formCliente"(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.keyToReRender++;
      }
    },
    form: {
      handler(newValue) {
        this.store.formCliente = {
          ...this.store.formCliente,
          ...newValue,
        };
      },
      deep: true,
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 1 && oldValue < newValue) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          if (this.$v.form.$anyError) {
            this.store.currentTab = oldValue;
            if (this.$v.form.nome.$anyError) {
              this.$toast.error("Informe o cliente!");
              this.clienteError = true;
            }
          }
          return;
        }
      }
    },
  },
};
</script>
