var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-wpp-fone","size":"md","centered":"","title":"Envio por whatsapp"},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('h5',[_vm._v("Envio por whatsapp")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":() => {
      close();
      _vm.openModal = false;
    }}},[_vm._v(" X ")])]}},{key:"modal-footer",fn:function({ ok, cancel }){return [_c('b-button',{on:{"click":() => {
      cancel();
      _vm.openModal = false;
    }}},[_vm._v(" Cancelar ")]),_c('b-button',{ref:"sendToWpp",attrs:{"variant":"primary"},on:{"click":_vm.confirmSendToWpp}},[_vm._v(" Enviar ")])]}}])},[_c('div',{staticClass:"mx-auto d-flex flex-column justify-content-center"},[_c('b-form-group',{attrs:{"id":"wppNumberInput","label-for":"wppNumberInput","label":"Whatsapp:"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #### ####', '(##) # #### ####']),expression:"['(##) #### ####', '(##) # #### ####']"}],key:_vm.render,model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }