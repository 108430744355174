<template>
  <div class="container-fluid">
    <div class="card-body">
      <b-row align-h="end">
        <button class="btn btn-primary font-weight-bold mr-2" style="height: 38px;" ref="import-orcamento-button"
          v-if="store.formNatureza.cfop && store.formNatureza.cfop !== '5929' && checkTpNF == 1"
          v-b-tooltip.top="'Somente irá importar com status: Autorizado'" @click="buscarOrcamentos">
          Importar Orçamento
        </button>
        <button class="btn btn-primary font-weight-bold mr-2" style="height: 38px;" ref="import-orcamento-button"
          v-if="store.formNatureza.cfop && store.formNatureza.cfop !== '5929' && checkTpNF == 1"
          v-b-tooltip.top="'Somente irá importar com status: Autorizado'" @click="buscarPedidos">
          Importar Pedido
        </button>
        <button class="btn btn-primary font-weight-bold" style="height: 38px;"
          v-if="store.formNatureza.cfop === '5929' && checkTpNF == 1" v-b-modal.importarNfce ref="import-nfce-button">
          Importar NFce
        </button>
        <button class="btn btn-primary font-weight-bold mr-2" style="height: 38px;" ref="import-orcamento-button"
          v-if="store.formNatureza.cfop && store.formNatureza.cfop !== '5929' && checkTpNF == 1"
          v-b-tooltip.top="'Somente irá importar as OS: Autorizadas, Finalizadas, Em andamento e Serviço concluído'"
          @click="buscarOS">
          Importar OS
        </button>
        <b-col md="2">
          <div class="cardHeader">
            <b-form-checkbox name="nfe-saida" switch size="lg" v-model="checkTpNF">
              NF-e de Saída
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-card>
        <b-tabs pills :horizontal="windowWidth < 768" :vertical="windowWidth > 768"
          :nav-wrapper-class="(windowWidth > 768 ? 'w-25' : 'w-100')" fill>
          <Natureza />
          <Cliente />
          <Produto />
          <Impostos />
          <Faturamento />
          <Transportador />
          <Intermediador />
        </b-tabs>
      </b-card>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <div v-if="canSaveNfe">
              <div class="form mb-5">
                <button v-if="store.currentTab !== 0" class="btn btn-secondary font-weight-bold mr-2"
                  @click="handlePrevious">
                  Anterior
                </button>
                <button v-if="store.isUpdate && store.currentTab !== 6" class="btn btn-primary font-weight-bold mr-4"
                  @click="handleNext">
                  Proximo
                </button>
                <button ref="submit_nova_nfe" class="btn btn-primary font-weight-bold" @click="salvarNFe">
                  Salvar
                </button>
                <button ref="submit_danfe" class="btn btn-secondary font-weight-bold mx-2" @click="salvarPreviaNFe">
                  Visualizar Pré-Danfe
                </button>
                <button v-b-modal.modalTransmitirNfe class="btn btn-secondary font-weight-bold mx-2">
                  Transmitir
                </button>
                <button ref="submit_previa_nfe" class="btn btn-clean font-weight-bold" @click="confirmCancel('nfe')">
                  Cancelar
                </button>
              </div>
            </div>
            <div v-else>
              <button :disabled="store.currentTab === 0" class="btn btn-secondary font-weight-bold mr-4"
                @click="handlePrevious">
                Anterior
              </button>
              <button class="btn btn-primary font-weight-bold" @click="handleNext">
                Proximo
              </button>
              <button ref="submit_previa_nfe" class="btn btn-clean font-weight-bold" @click="confirmCancel('nfe')">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalTransmitirNfe" title="Transmissão" centered>
      <b-form class="form">
        <div class="d-flex flex-column mx-auto" style="gap: 0.5rem; width: 40rem">
          <CheckBox label="Imprimir DANFE" v-model="printDanfe" />
          <CheckBox label="Enviar arquivo XML por e-mail" v-model="xmlToEmail" v-if="store.formCliente.email" />
          <CheckBox v-b-tooltip="checkNutsDigital()" :disabled="!nutsDigital" label="Gerar boleto das parcelas"
            v-model="generateBoleto" />
          <CheckBox label="Imprimir Duplicata" v-model="printDuplicata" />
        </div>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="transmitirNFe" ref="transmitir_nfe">
          Transmitir
        </b-button>
        <b-button @click="cancel()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal id="importarNfce" title="Importar NFce" centered>
      <b-form class="form">
        <b-col>
          <b-form-group label="NFCe" label-for="nfce">
            <VueSelect multiple ref="nfce" :filterable="false" :options="nfceToImport" v-model="selectedNFCe"
              @search="onSearchNFCe">
              <template slot="no-options"> Informe o número da NFCe</template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.numero }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.numero }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="importarNFce" ref="importar_nfce">
          Importar
        </b-button>
        <b-button @click="cancel()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal id="importarOS" title="Importar OS" centered>
      <b-form class="form">
        <b-row align-h="center">
          <b-col>
            <b-form-group :key="keyToReRender" label="OS" label-for="os">
              <VueSelect multiple ref="os" :filterable="false" :options="osToImport" v-model="selectedOS"
                @search="onSearchOS">
                <template slot="no-options"> Informe a OS</template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.numero }} - {{ option.cliente }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.numero }} - {{ option.cliente }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="importarOS" ref="importar_os">
          Importar
        </b-button>
        <b-button @click="cancel()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal id="importarOrcamento" title="Importar Orçamento" centered>
      <b-form class="form">
        <b-row align-h="center">
          <b-col>
            <b-form-group :key="keyToReRender" label="Orçamento">
              <VueSelect multiple ref="orcamento" :filterable="false" :options="orcamentosToImport"
                v-model="selectedOcamentos" @search="onSearchOrcamento">
                <template slot="no-options"> Informe o orçamento</template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.numero }} - {{ option.cliente }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.numero }} - {{ option.cliente }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="importarOrcamento" ref="importar_orcamento">
          Importar
        </b-button>
        <b-button @click="cancel()"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal id="importarPedido" title="Importar Pedido" centered>
      <b-form class="form">
        <b-row align-h="center">
          <b-col>
            <b-form-group :key="keyToReRender" label="Pedido" label-for="pedido">
              <VueSelect multiple ref="pedido" :filterable="false" :options="pedidosToImport" v-model="selectedPedidos"
                @search="onSearchPedido">
                <template slot="no-options">Busque pelo número do pedido</template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.numero }} - {{ option.cliente }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.numero }} - {{ option.cliente }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="importarPedido" ref="importar_pedido">
          Importar
        </b-button>
        <b-button @click="() => {
          cancel()
          selectedPedidos = null
        }"> Cancelar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import FormatMixin from "../../Application/mixins/FormatMixin";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import CheckBox from "../../../components/Form/Checkbox.vue";
import PagamentosMixin from "../../Pdv/mixins/PagamentosMixin";
import InstallmentsMixin from "../../Application/mixins/InstallmentsMixin";
import Natureza from "./componentes/NaturezaTab.vue";
import Cliente from "./componentes/ClienteTab.vue";
import Produto from "./componentes/ProdutoTab.vue";
import Transportador from "./componentes/TransportadorTab.vue";
import Faturamento from "./componentes/FaturamentoTab.vue";
import Impostos from "./componentes/ImpostosTab.vue";
import { store } from "../storeNfe";
import Intermediador from "./componentes/Intermediador.vue";
import VueSelect from "vue-select";
import _ from "lodash";
import swal from "sweetalert";

export default {
  name: "app-nfe-form",
  props: {
    notaCompra: {
      type: Object,
      default: () => { },
    },
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      windowWidth: window.innerWidth,
      pedidosToImport: [],
      selectedPedidos: null,
      openTransmitirNFeModal: false,
      selectedOcamentos: null,
      formImpostos: {},
      formProduto: {},
      nfceNumber: null,
      orcamentosToImport: null,
      formTransportador: {},
      formFaturamento: {},
      formToSendToImpostoApi: {},
      productOfTradeCollection: [],
      printDanfe: true,
      xmlToEmail: false,
      generateBoleto: false,
      nfeId: null,
      checkTpNF: true,
      nfceToImport: [],
      selectedNFCe: null,
      osToImport: null,
      selectedOS: null,
      printDuplicata: false,
    };
  },
  mixins: [
    validationMixin,
    FormatMixin,
    SistemaMixin,
    ValidacaoMixin,
    PagamentosMixin,
    InstallmentsMixin,
  ],
  components: {
    Natureza,
    Cliente,
    Produto,
    Transportador,
    Faturamento,
    Impostos,
    Intermediador,
    CheckBox,
    VueSelect,
  },
  watch: {
    total(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorTotal = newValue;
      }
    },
    checkTpNF(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.store.tpNF = newValue ? 1 : 0;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    if (this.$route?.params?.nfeData?.nfeId) {
      this.id = this.$route.params.nfeData.nfeId;
      if (this.$route.params.nfeData.tpNF) {
        this.checkTpNF = true;
      }

      if (!this.$route.params.nfeData.tpNF || this.$route.params.nfeData.tpNF == 0) {
        this.checkTpNF = false;
      }
    }
    this.getDataInit();

    this.store.currentTab = 0;

    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    onSearchOS(search, loading) {
      loading(true);
      this.searchOS(loading, search, this);
    },
    async importarPedido() {
      this.addLoadingToButton('importar_pedido')
      const pedidos = this.selectedPedidos.map(({ id_pedido }) => ({
        id_pedido,
      }))
      try {
        const { data } = await this.$http.post('/pedido/importar', {
          pedidos
        })
        const [formFaturamento, formProduto, formCliente, formTransportador] = this.extractDataFromImport(data);

        this.store.transportadorImported = formTransportador;
        this.store.pedidos = pedidos;
        
        if (formFaturamento) this.store.formFaturamento = formFaturamento;

        this.store.formProduto = formProduto;
        this.store.formFaturamento.informacoes_adicionais = data.observation;
        this.store.formImpostos.discount = data.discount;

        if (this.store.cfop) {
          const produtosWithCFOPAplied = this.store.formProduto.produtos.map(produto => ({
            ...produto,
            product: {
              ...produto.product,
              cfop: this.store.cfop
            }
          }));
          this.store.formProduto.produtos = produtosWithCFOPAplied;
        }
        if (formCliente) this.store.formCliente = formCliente;
        this.$toast.success("Pedido(s) importado(s) com sucesso!");
        this.$bvModal.hide('importarPedido');
        this.removeLoadingToButton('importar_pedido');
        this.selectedPedidos = null
        await this.calcImposto();
      } catch (error) {
        this.$bvModal.hide('importarPedido');
        this.removeLoadingToButton('importar_pedido')
      }
    },
    async buscarOrcamentos() {
      this.setLoading(true)
      try {
        const response = await this.$http.post('/orcamento/orcamentos-autorizados');
        this.orcamentosToImport = response.data.sort((a, b) => {
          if (a.id_orcamento < b.id_orcamento) return -1;
          if (a.id_orcamento > b.id_orcamento) return 1;
          return 0;
        });
        this.$bvModal.show('importarOrcamento');
      } catch (error) {
        console.log(error)
      }
      this.setLoading(false)
    },
    async buscarPedidos() {
      this.setLoading(true)
      try {
        const response = await this.$http.post('/pedido/pedidos-autorizados');
        this.pedidosToImport = response.data.sort((a, b) => {
          if (a.id_pedido < b.id_pedido) return -1;
          if (a.id_pedido > b.id_pedido) return 1;
          return 0;
        });
        this.$bvModal.show('importarPedido');
      } catch (error) {
        console.log(error)
      }
      this.setLoading(false)
    },
    async buscarOS() {
      this.setLoading(true)
      try {
        const responseOS = await this.$http.post('/ordemservico/os-autorizadas');
        this.osToImport = responseOS.data.sort((a, b) => {
          if (a.id_ordem_servico < b.id_ordem_servico) return -1;
          if (a.id_ordem_servico > b.id_ordem_servico) return 1;
          return 0;
        });
        this.$bvModal.show('importarOS');
      } catch (error) {
        console.log(error);
      }
      this.setLoading(false)
    },
    searchOS: _.debounce(async (loading, search, vm) => {
      vm.osToImport = [];
      await vm.$nextTick();
      if (!search) {
        loading(false);
        return;
      };
      const response = await vm.$http.post('/ordemservico/os-autorizadas', {
        numero: search,
      });
      vm.osToImport = _.cloneDeep(response.data);
      loading(false);
    }, 500),
    onSearchOrcamento(search, loading) {
      loading(true);
      this.searchOrcamento(loading, search, this);
    },
    searchOrcamento: _.debounce(async (loading, search, vm) => {
      vm.orcamentosToImport = [];
      await vm.$nextTick();
      if (!search) {
        loading(false);
        return;
      };
      const response = await vm.$http.post('/orcamento/orcamentos-autorizados', {
        numero: search,
      });
      vm.orcamentosToImport = _.cloneDeep(response.data);
      loading(false);
    }, 500),
    onSearchPedido(search, loading) {
      loading(true);
      this.searchPedido(loading, search, this);
    },
    searchPedido: _.debounce(async (loading, search, vm) => {
      vm.pedidosToImport = [];
      await vm.$nextTick();
      if (!search) {
        loading(false);
        return;
      };
      const response = await vm.$http.post('/pedido/pedidos-autorizados', {
        numero: search,
      });
      vm.pedidosToImport = _.cloneDeep(response.data);
      loading(false);
    }, 500),
    onSearchNFCe(search, loading) {
      loading(true);
      this.searchNFCe(loading, search, this);
    },
    searchNFCe: _.debounce(async (loading, search, vm) => {
      vm.nfceToImport = [];
      await vm.$nextTick();
      if (isNaN(parseInt(search))) {
        loading(false);
        return;
      };
      const response = await vm.$http.post('/nfe/busca-nfce', {
        numero: parseInt(search),
      });
      vm.nfceToImport = _.cloneDeep(response.data);
      loading(false);
    }, 1000),
    async getDataInit() {


      this.getPagamentos();
      if (this.$route.params.nfeData.nfeId) {
        this.setLoading(true);
      }
    },
    async importarOS() {
      this.addLoadingToButton('importar_os')
      try {
        const os = this.selectedOS.map(({ id_ordem_servico }) => ({
          id_ordem_servico,
        }));
        const response = await this.$http.post('/nfe/importar-os', {
          os,
        });

        this.store.os = os;
        const [formFaturamento, formProduto, formCliente] = this.extractDataFromImport(response.data);

        if (formFaturamento) this.store.formFaturamento = formFaturamento;

        this.store.formProduto = formProduto;
        this.store.formFaturamento.informacoes_adicionais = response.data.observation;
        this.store.formImpostos.discount = response.data.discount;

        if (this.store.cfop) {
          const produtosWithCFOPAplied = this.store.formProduto.produtos.map(produto => ({
            ...produto,
            product: {
              ...produto.product,
              cfop: this.store.cfop
            }
          }));
          this.store.formProduto.produtos = produtosWithCFOPAplied;
        }
        if (formCliente) this.store.formCliente = formCliente;
        this.selectedOS = null;
        this.$toast.success("OS(s) importada(s) com sucesso!");
        await this.calcImposto();
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton('importar_os')
      this.$bvModal.hide('importarOS')
    },
    async importarOrcamento() {
      this.addLoadingToButton('importar_orcamento')
      try {
        const orcamentos = this.selectedOcamentos.map(({ id_orcamento }) => ({
          id_orcamento,
        }));
        const response = await this.$http.post('/nfe/importar-orcamento', {
          orcamentos,
        });

        this.store.orcamentos = orcamentos;
        const [formFaturamento, formProduto, formCliente] = this.extractDataFromImport(response.data);

        if (formFaturamento) this.store.formFaturamento = formFaturamento;

        this.store.formProduto = formProduto;
        this.store.formFaturamento.informacoes_adicionais = response.data.observation;
        this.store.formImpostos.discount = response.data.discount;

        if (this.store.cfop) {
          const produtosWithCFOPAplied = this.store.formProduto.produtos.map(produto => ({
            ...produto,
            product: {
              ...produto.product,
              cfop: this.store.cfop
            }
          }));
          this.store.formProduto.produtos = produtosWithCFOPAplied;
        }
        if (formCliente) this.store.formCliente = formCliente;
        this.selectedOcamentos = null;
        this.$toast.success("Orçamento(s) importado(s) com sucesso!");
        await this.calcImposto();
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton('importar_orcamento')
      this.$bvModal.hide('importarOrcamento')
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async calcImposto() {
      this.dataSendToApi = this.formatDataToSendToApi(
        this.store.formNatureza,
        this.form,
        this.store.formCliente,
        this.store.formFaturamento.pagamentos.length === 0 ? null : this.store.formFaturamento,
        this.store.formImpostos
      );
      const { dados } = await this.getTotaisNfe(this.dataSendToApi);
      this.store.formImpostos = {
        ...this.store.formImpostos,
        ...dados.total,
      };

      this.produtos = dados.productOfTradeCollection;
    },
    async importarNFce() {
      this.addLoadingToButton('importar_nfce');
      try {
        const nfces = this.selectedNFCe.map(({ id }) => ({
          id,
        }));
        const { data: nfceData } = await this.$http.post('/nfe/importar-nfce', {
          nfces,
        });
        const [formFaturamento, formProduto, formCliente] = this.extractDataFromImport(nfceData);
        this.store.formFaturamento = formFaturamento;
        this.store.formProduto = formProduto;

        if (this.store.cfop) {
          const produtosWithCFOPAplied = this.store.formProduto.produtos.map(produto => ({
            ...produto,
            product: {
              ...produto.product,
              cfop: this.store.cfop
            }
          }));
          this.store.formProduto.produtos = produtosWithCFOPAplied;
        }

        if (formCliente) this.store.formCliente = formCliente;
        this.store.refNFCe = nfceData.refNFCe;
        this.$toast.success('NFce Importada com sucesso!')
        this.selectedNFCe = null;
      } catch (error) {
        this.$toast.error('Erro ao importar NFce!');
        this.selectedNFCe = null;
        console.log(error);
      }
      this.$bvModal.hide('importarNfce')
      this.removeLoadingToButton('importar_nfce');
      this.selectedNFCe = null;
    },
    async transmitirNFe() {
      const submitButton = this.$refs["transmitir_nfe"];

      this.$toast.info("Criando nfe...");

      const data = {
        ...this.formatDataToSendToApi(
          this.store.formNatureza,
          this.store.formProduto,
          this.store.formCliente,
          this.store.formFaturamento,
          this.store.formImpostos,
          this.store.formIntermediador.usuario === null
            ? undefined
            : this.store.formIntermediador,
          this.store.formTransportador,
          this.store.formRural),
        refNFCe: this.store.refNFCe,
      };
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.setAttribute("disabled", "");
      try {
        if (this.nfeId) {
          await this.$http.post("/nfe/update-nfe", {
            dados: {
              ...data,
              id: this.nfeId,
              tpNF: this.store.tpNF,
            },
          });
        } else {
          const id = await this.criarNfe(data, this.store.tpNF);
          this.nfeId = id;
        }
        if (!this.nfeId) {
          this.$toast.error("Erro ao criar NFe!");
          this.$toast.error("Erro ao transmitir Nfe");
          this.$toast.error(
            "Faça as correções necessárias e transmita novamente"
          );
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.removeAttribute("disabled", "");
          return;
        }

        this.$toast.success("NFe criada com sucesso!");
        this.$toast.info("Transmitindo...");

        const response = await this.transmitirNfeApi(
          this.nfeId,
          this.xmlToEmail,
          this.printDanfe,
          this.generateBoleto
        );

        if (!response) {
          this.$toast.error("Erro ao transmitir Nfe");
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.removeAttribute("disabled", "");
          return;
        }

        this.$toast.success("NFe transmitida com sucesso!");
        console.log(this.printDanfe);
        if (this.printDanfe) {
          this.$toast.info("Gerando pdf...");
          const { data } = await this.gerarPdf(this.nfeId, this.printDuplicata);
          this.pdfUrl = data["danfe-url"];

          this.$toast.success("Pdf gerado com sucesso!");
          window.open(this.pdfUrl, "_blank");

          if (data["duplicata"]) {
            window.open(data['duplicata'], "_blank");
          }
        }

        if (response.data.boletos) {
          response.data.boletos.forEach(function (boleto) {
            window.open(boleto.url_boleto, "_blank");
          });
          this.$toast.success("Boleto gerado com sucesso!");
        }

        this.store.refNFCe = null;
        this.$router.push({ name: "nfe" });
      } catch (error) {
        this.$toast.error("ERRO!", JSON.stringify(error, 2, 0));
      }

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.removeAttribute("disabled", "");
      this.store.dataFetched = false;
    },

    async salvarNFe() {
      this.addLoadingToButton('submit_nova_nfe');
      if (Object.keys(this.$route.params).length !== 0 && this.store.isUpdate) {
        const { nfeId: id } = this.$route.params.nfeData;
        const data = this.formatDataToSendToApi(
          this.store.formNatureza,
          this.store.formProduto,
          this.store.formCliente,
          this.store.formFaturamento,
          this.store.formImpostos,
          this.store.formIntermediador.usuario === null
            ? undefined
            : this.store.formIntermediador,
          this.store.formTransportador,
          this.store.formRural
        );
        try {
          await this.$http.post("/nfe/update-nfe", {
            dados: {
              ...data,
              id,
              tpNF: this.store.tpNF,
            },
          });
          this.$toast.success("NFe editada com sucesso!");
          this.$router.push({ name: "nfe" });
        } catch (error) {
          this.$toast.error("Erro ao editar NFe!");
        }
      } else {
        try {
          const data = {
            ...this.formatDataToSendToApi(
              this.store.formNatureza,
              this.store.formProduto,
              this.store.formCliente,
              this.store.formFaturamento,
              this.store.formImpostos,
              this.store.formIntermediador.usuario === null
                ? undefined
                : this.store.formIntermediador,
              this.store.formTransportador,
              this.store.formRural),
            refNFCe: this.store.refNFCe,
          };
          if (this.nfeId) {
            await this.$http.post("/nfe/update-nfe", {
              dados: {
                ...data,
                id: this.nfeId,
                tpNF: this.store.tpNF,
              },
            });
          } else {
            const id = await this.criarNfe(data, this.store.tpNF);
            this.nfeId = id;
          }
          this.store.refNFCe = null;
          this.$toast.success("NFe salva com sucesso!");
          this.$router.push({ name: "nfe" });
        } catch (error) {
          this.$toast.error("Erro ao salvar NFe");
          console.log(error);
        }
        return;
      }
      this.removeLoadingToButton('submit_nova_nfe');
      this.store.dataFetched = false;
    },
    async salvarPreviaNFe() {
      this.addLoadingToButton('submit_danfe');
      if (Object.keys(this.$route.params).length !== 0 && this.store.isUpdate) {
        const { nfeId: id } = this.$route.params.nfeData;
        const data = this.formatDataToSendToApi(
          this.store.formNatureza,
          this.store.formProduto,
          this.store.formCliente,
          this.store.formFaturamento,
          this.store.formImpostos,
          this.store.formIntermediador.usuario === null
            ? undefined
            : this.store.formIntermediador,
          this.store.formTransportador,
          this.store.formRural
        );
        try {
          await this.$http.post("/nfe/update-nfe", {
            dados: {
              ...data,
              id,
              tpNF: this.store.tpNF,
            },
          });
          this.$toast.success("NFe editada com sucesso!");
          this.removeLoadingToButton('submit_danfe');
          this.imprimirPDF(id);
          this.removeLoadingToButton('submit_danfe');
        } catch (error) {
          this.$toast.error("Erro ao buscar NFe!");
          this.removeLoadingToButton('submit_danfe');
        }
      } else {
        try {
          const data = {
            ...this.formatDataToSendToApi(
              this.store.formNatureza,
              this.store.formProduto,
              this.store.formCliente,
              this.store.formFaturamento,
              this.store.formImpostos,
              this.store.formIntermediador.usuario === null
                ? undefined
                : this.store.formIntermediador,
              this.store.formTransportador,
              this.store.formRural),
            refNFCe: this.store.refNFCe,
          };
          if (this.nfeId || this.store.isUpdate) {
            await this.$http.post("/nfe/update-nfe", {
              dados: {
                ...data,
                id: this.nfeId,
                tpNF: this.store.tpNF,
              },
            });
            this.imprimirPDF(this.nfeId);
            this.removeLoadingToButton('submit_danfe');
          } else {
            const id = await this.criarNfe(data, this.store.tpNF);
            this.nfeId = id;

            this.imprimirPDF(this.nfeId);
          }
          this.store.refNFCe = null;
          this.$toast.success("NFe salva com sucesso!");
          this.removeLoadingToButton('submit_danfe');
        } catch (error) {
          this.$toast.error("Erro ao salvar NFe");
          this.removeLoadingToButton('submit_danfe');
          console.log(error);
        }
        return;
      }
      this.removeLoadingToButton('submit_danfe');
      this.store.dataFetched = false;
    },
    imprimirPDF(id) {
      swal({
        title: "Deseja visualizar a prévia da NFe?",
        text: "Este documento ainda não tem validade fiscal, somente após ser autorizado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/print-nfe", {
            id: id,
          })
          .then((result) => {
            const resultData = result["data"]["danfe-url"];
            if (resultData) {
              window.open(result["data"]["danfe-url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("PDF gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    handleNext() {
      if (this.store.currentTab === 6) return;
      this.store.currentTab++;
    },
    handlePrevious() {
      if (this.store.currentTab === 0) return;
      this.store.currentTab--;
    },
    checkNutsDigital() {
      if (!this.nutsDigital) {
        return "Recurso disponível para quem possui a Nuts Digital, realize o seu cadastro";
      }

      return "Os boletos serão enviados para o e-mail do cliente, conforme está lançado na NFe";
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "nutsDigital",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },
    toolbarDisplay() {
      return true;
    },
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    },
    canSaveNfe() {
      return this.store.currentTab === 6 || this.store.isUpdate;
    },
  },
  created() {
    this.store.isUpdate = false;
    if (Object.keys(this.$route.params).length !== 0) {
      const { nfeId } = this.$route.params.nfeData;
      this.store.isUpdate = true;
      this.nfeId = nfeId;
      if (!nfeId) {
        this.store.isUpdate = false;
      }
    }
    this.store.tpNF = 1;
  },
};
</script>

<style>
@media (max-width: 991.98px) {
  .container {
    padding: 0
  }

  .card-body-container {
    padding: 0rem;
  }

  .card-body {
    padding: 1rem;
  }
}

.action-buttons {
  display: flex;
}

.cardHeader {
  display: flex;
  max-width: 11rem;
  justify-content: center;
  background-color: white;
  margin-bottom: 5px;
  border-radius: 14px;
  padding: 0.5rem;
  margin-left: auto;
}

.busca-produto .vs__search {
  font-weight: 400;
  font-size: 1.45rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
  text-transform: uppercase !important;
}

.busca-produto .vs__dropdown-toggle {
  border: 0px !important;
}

.busca-produto .vs__actions {
  display: none;
}


.busca-produto .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-produto .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.busca-cliente .vs__search {
  font-size: 18px;
}

.busca-cliente .vs__dropdown-toggle {
  color: #ee7361;
  background-color: #fff;
  border: 2px solid #dedede;
  height: 40px;
}

.busca-cliente .vs--open .vs__dropdown-toggle {
  background-color: #fff;
  border: 2px solid #ee7361;
}

.busca-cliente .vs__actions {
  display: none;
}

.busca-cliente .vs__dropdown-option--highlight,
.vs__dropdown-menu li:hover,
.vs__dropdown-menu li:focus {
  background-color: #ee73615e !important;
  color: #ee7361 !important;
}

.busca-cliente .vs__dropdown-menu {
  color: #ee7361 !important;
}

.busca-cliente .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-cliente .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-pgto {
  font-weight: bold;
  font-size: 18px;
  color: #4f4f4f;
}

.add-pgto {
  font-weight: bold;
  font-size: 22px;
  color: #ee7361;
  float: right;
}

.input-quantidade {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  background: transparent;
  border: transparent;
  color: #fff;
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 0px;
  text-align: right;
}

.input-quantidade:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  border: 0px;
  background-color: transparent;
  color: #fff;
}

.card {
  border: 0;
}

h4 {
  color: #6d747c;
  /* font-weight: 900; */
}

.btn-finalizar-venda {
  background-color: #eee5ff !important;
  font-size: 1.25rem !important;
  text-align: center !important;
  cursor: pointer;
}

.card-total-right p,
.card-total-left p {
  color: #fff !important;
}

.card-header h5,
.card-header h3 {
  margin-bottom: 0rem;
}

.card-header .form-control {
  height: auto;
  margin-top: 0px;
}

.card-header .busca-produto {
  margin-top: 2px;
}


.card-titulo-itens .card-body-container {
  padding: 0px 0 !important;
}

.card-itens .table tr th {
  padding: 5px;
  font-size: 12px;
}

.card-itens .table tr td,
.card-total-right p,
.card-total-left p {
  font-size: 12px;
}

.tableItensCompra {
  max-height: 300px !important;
  height: calc(100vh - 395px) !important;
}

.card-total-left,
.card-total-right {
  height: 80px !important;
}

.btn.btn-secondary {
  color: #ee7361;
  background-color: #fff;
  border-color: #fff;
}
</style>
